import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
//selectors
import { getLangJson } from '../../store/selectors/LangSelector';
import { getFetching } from '../../store/selectors/AppSelector';
//svg
import {ReactComponent as MoneyBag} from '../../assets/images/moneyBag.svg';
//components
import Loader from '../../components/common/Loader';

function BalanceCard({data, withnote}) {
  const t = useSelector((state) => getLangJson({ state })),
        isFetching = useSelector((state) => getFetching({ state })),
        history = useHistory();

  return (
    <div className="card card-shadow">
        <div className="balance-card-header">
          <MoneyBag/>
          <div className="available-balance">
            {isFetching? 
              <Loader heightval= {30} /> 
            :
              <p className="bold-txt sz-24 title-color">
                $ {data?.balance>=0? (Math.round((data?.balance + Number.EPSILON) * 1000) / 1000).toFixed(3) : ""}
                {data?.hold > 0 && (<span className="regular-txt sz-18 subtitle-color"> ($ {(Math.round((data?.hold + Number.EPSILON) * 1000) / 1000).toFixed(3)} on hold)</span>)}
              </p>
            }
            <p className="regular-txt sz-16 subtitle-color">{t.home.availableBalance}</p>
          </div>
        </div>
        {withnote?
          <div className="balance-card-footer-notes">
            <ul>
              <li className="regular-txt sz-12 title-color">{t.transactionHistory.balanceNote1}</li>
            </ul>
          </div>
        :
          <div className="balance-card-footer">
            <button onClick={() => history.push('/transactions-history')} className="withdraw-btn regular-txt sz-12">{t.home.transfer}</button>
          </div>
        }
    </div>
  );
}

export default BalanceCard;