import React from "react";
import LoadingIcon from './LoadingIcon';
import PropTypes from 'prop-types';

const Loader = ({ heightval }) => {

  return (
    <div style={{ height: heightval??10 }} className="modal-loader-wrapper">
		<LoadingIcon />
	</div>
  );
};

Loader.propTypes = {
  height: PropTypes.string,
};

export default Loader;