import { GET_LATEST_BOUQUET, GET_BALANCE, GET_MONTH_SALES_REPORT, GET_SALES_REPORT } from '../types/HomeTypes';
//actions
import { setFetching, setReportFetching } from './AppActions';
//service
import HomeService from '../../services/HomeService';
//toast
import { toast } from 'react-toastify';
//constant
import { completeMonthData, completeDayData, completeWeekData, completeYearData } from '../../constants/Helpers';

export const getLatestBouquet = () => async (dispatch) => {
  dispatch(setFetching(true));
	try {
    const res = await HomeService.getLatestBouquetData();
    dispatch({
        type: GET_LATEST_BOUQUET,
        latestBouquet: res.data,
    });
    dispatch(setFetching(false));
  } catch (err) {
      dispatch(setFetching(false));
  }
};

export const getBalance = () => async (dispatch) => {
  dispatch(setFetching(true));
	try {
    const res = await HomeService.getBalanceData();
    dispatch({
        type: GET_BALANCE,
        balance: res.data,
    });
    dispatch(setFetching(false));
  } catch (err) {
      dispatch(setFetching(false));
      toast.error(err?.response?.data?.message);
  }
};

export const getMonthSalesReport = (params) => async (dispatch) => {
  dispatch(setReportFetching(true));
  let res = {data: []};
  let totalOrders = 0;
  let totalCommission = 0;
	try {
    res = await HomeService.getSalesReportMonthData(params);
    let currentMonth = new Date(params.day).getMonth();
    let nextMonth = currentMonth + 1;
    res.data.map((sr, ind) => {
      if((sr.date) < new Date(params.day).getDate()){
        if(nextMonth === 12){
          sr.date = new Date(new Date(new Date(new Date().setFullYear(new Date(params.day).getFullYear()+1)).setMonth(0)).setDate(sr.date));
        }else{
          sr.date = new Date(new Date(new Date(new Date().setFullYear(new Date(params.day).getFullYear())).setMonth(nextMonth)).setDate(sr.date));
        }
      }else{
        if(ind !== 0){
          if((res.data[ind-1].date < new Date(params.day).getDate()) || (sr.date === "1" && ind !== 0)){
            sr.date = new Date(new Date(new Date(new Date().setFullYear(new Date(params.day).getFullYear())).setMonth(nextMonth)).setDate(sr.date));
          }else{
            sr.date = new Date(new Date(new Date(new Date().setFullYear(new Date(params.day).getFullYear())).setMonth(currentMonth)).setDate(sr.date));
          }
        }else{
          sr.date = new Date(new Date(new Date(new Date().setFullYear(new Date(params.day).getFullYear())).setMonth(currentMonth)).setDate(sr.date));
        }
      }
      totalOrders += sr.count;
      totalCommission += sr.total;
    })
    res.data = completeMonthData(res.data, new Date(params.day));
    dispatch({
      type: GET_MONTH_SALES_REPORT,
      monthSalesReport: {data: res.data, totalOrders: totalOrders, totalCommission: totalCommission},
    });
    dispatch(setReportFetching(false));
  } catch (err) {
      dispatch(setReportFetching(false));
      toast.error(err?.response?.data?.message);
  }
};

export const getSalesReport = (type, params) => async (dispatch) => {
  dispatch(setReportFetching(true));
  let res = {data: []};
  let totalOrders = 0;
  let totalCommission = 0;
	try {
    if(type === "day"){
      res = await HomeService.getSalesReportDayData(params);
      if(res.data.length>0){
        res.data.map((sr) => {
          sr.date = new Date(new Date(new Date(new Date(params.day).setHours(sr.date)).setMinutes(0)).setSeconds(0));
          totalOrders += sr.count;
          totalCommission += sr.total;
        })
        res.data = completeDayData(res.data, new Date(params.day));
      }
    }else{
      if(type === "week"){
        res = await HomeService.getSalesReportWeekData(params);
        let currentMonth = new Date(params.day).getMonth();
        let nextMonth = currentMonth + 1;
        if(res.data.length>0){
          res.data.map((sr) => {
            if((sr.date) < new Date(params.day).getDate()){
              if(nextMonth === 12){
                sr.date = new Date(new Date(new Date(new Date().setFullYear(new Date(params.day).getFullYear()+1)).setMonth(0)).setDate(sr.date));
              }else{
                sr.date = new Date(new Date(new Date(new Date().setFullYear(new Date(params.day).getFullYear())).setMonth(nextMonth)).setDate(sr.date));
              }
            }else{
              sr.date = new Date(new Date(new Date(new Date().setFullYear(new Date(params.day).getFullYear())).setMonth(currentMonth)).setDate(sr.date));
            }
            totalOrders += sr.count;
            totalCommission += sr.total;
          })
          res.data = completeWeekData(res.data, new Date(params.day));
        }
      }else{
        if(type === "month"){
          res = await HomeService.getSalesReportMonthData(params);
          let currentMonth = new Date(params.day).getMonth();
          let nextMonth = currentMonth + 1;
          if(res.data.length>0){
            res.data.map((sr, ind) => {
              if((sr.date) < new Date(params.day).getDate()){
                if(nextMonth === 12){
                  sr.date = new Date(new Date(new Date(new Date().setFullYear(new Date(params.day).getFullYear()+1)).setMonth(0)).setDate(sr.date));
                }else{
                  sr.date = new Date(new Date(new Date(new Date().setFullYear(new Date(params.day).getFullYear())).setMonth(nextMonth)).setDate(sr.date));
                }
              }else{
                if(ind !== 0){
                  if((res.data[ind-1].date < new Date(params.day).getDate()) || (sr.date === "1" && ind !== 0)){
                    sr.date = new Date(new Date(new Date(new Date().setFullYear(new Date(params.day).getFullYear())).setMonth(nextMonth)).setDate(sr.date));
                  }else{
                    sr.date = new Date(new Date(new Date(new Date().setFullYear(new Date(params.day).getFullYear())).setMonth(currentMonth)).setDate(sr.date));
                  }
                }else{
                  sr.date = new Date(new Date(new Date(new Date().setFullYear(new Date(params.day).getFullYear())).setMonth(currentMonth)).setDate(sr.date));
                }
              }
              totalOrders += sr.count;
              totalCommission += sr.total;
            })
            res.data = completeMonthData(res.data, new Date(params.day));
          }
        }else{
          if(type === "year"){
            res = await HomeService.getSalesReportYearData(params);
            let currentYear = new Date(params.day).getFullYear();
            let nextYear = currentYear + 1;
            if(res.data.length>0){
              res.data.map((sr) => {
                if((sr.date - 1) < new Date(params.day).getMonth()){
                  sr.date = new Date(new Date(new Date().setFullYear(nextYear)).setMonth(sr.date - 1))
                }else{
                  sr.date = new Date(new Date(new Date().setFullYear(currentYear)).setMonth(sr.date - 1))
                }
                totalOrders += sr.count;
                totalCommission += sr.total;
              })
              res.data = completeYearData(res.data, new Date(params.day));
            }
          }
        }
      }
    }
    dispatch({
        type: GET_SALES_REPORT,
        salesReport: {data: res.data, totalOrders: totalOrders, totalCommission: totalCommission},
    });
    dispatch(setReportFetching(false));
  } catch (err) {
    dispatch(setReportFetching(false));
    toast.error(err?.response?.data?.message);
  }
};