import { SET_BANK_DETAILS, GET_BANK_DETAILS, UPDATE_BANK_DETAILS } from '../types/BankTypes';
//constants
import { updateObject } from '../../constants/Helpers';

const initialState = {
  bankDetails: {},
};

const BankReducer = (state = initialState, action) => {
	switch (action.type) {
    case SET_BANK_DETAILS: {
      return updateObject(state, { bankDetails: action.bankDetails });
    }
    case GET_BANK_DETAILS: {
      return updateObject(state, { bankDetails: action.bankDetails });
    }
    case UPDATE_BANK_DETAILS: {
      return updateObject(state, { bankDetails: action.bankDetails });
    }
    default:
      return state; 
	}
};

export default BankReducer;