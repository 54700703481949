import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
//selectors
import { getLangJson, getLang } from '../../store/selectors/LangSelector';
import { getFetching } from '../../store/selectors/AppSelector';
//constant
import { formatDate } from '../../constants/Helpers';
//actions
import { setSelectedDesign, deleteDesign } from '../../store/actions/DesignActions';
//components
import Loader from '../../components/common/Loader';
//svg
import {ReactComponent as Bin} from '../../assets/images/bin.svg';

function DesignCard({data, isClickable, isSelectable, handleToggle}) {
  const t = useSelector((state) => getLangJson({ state })),
        lang = useSelector((state) => getLang({ state })),
        isFetching = useSelector((state) => getFetching({ state })),
        dispatch = useDispatch();

  const handleDesignSelect = (e) => {
    if(isSelectable){
      handleToggle(data);
    }else{
      if(e.target.tagName !== "svg" && e.target.tagName !== "path"){
        handleToggle(true);
        dispatch(setSelectedDesign(data));
      }
    }
  };

  const handleDeleteDesign = (id) => {
    dispatch(deleteDesign(id));
  }

  return (
    <div className={`card card-shadow ${isClickable? "clickable-card" : ""}`} onClick={(e) => isClickable? handleDesignSelect(e) : null}>
      {data?.status === 2 && (
        <Bin className="bin-icon" onClick={() => handleDeleteDesign(data?.id)} />
      )}
      {(isFetching && Object.keys(data).length===0)? <Loader/> :<div className="design-card-body">
        <div className="img-design-container">
          <div className="img-design" style={data?.images?{backgroundImage: `url(${data?.images[0]})`}:{}}></div>
        </div>
        <div className="info-design-container">
          <p className="medium-txt sz-16 title-design">{data.name}</p>
          <p className="medium-txt sz-12 title-design">{t.home.requestId}{' '}<span className="regular-txt light-date">#{data?.id}</span></p>
          <p className="medium-txt sz-12 title-design">{t.home.requestDate}{' '}<span className="regular-txt light-date">{data?.createDate? formatDate(data?.createDate, lang) : ""}</span></p>
          <p className={`medium-txt sz-14 ${data?.status === 0?'under-review':data?.status === 1?'approved':data?.status === 2?'rejected':''}-design`}>
            {data?.status === 0? 
              t.home.underReview
            :
              data?.status === 1?
                t.home.accepted
              :
                data?.status === 2?
                  t.home.rejected
                :
                  ""
            }
          </p>
        </div>
      </div>}
    </div>
  );
}

export default DesignCard;