import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import imageCompression from 'browser-image-compression';
//selectors
import { getLangJson, getLang } from '../store/selectors/LangSelector';
//svg
// import { ReactComponent as SmallClose } from '../assets/images/small-close.svg';
import { ReactComponent as AddImg } from '../assets/images/add-img.svg';
import { ReactComponent as DeleteIcon } from '../assets/images/delete.svg';
//components
import FixedButton from '../components/common/FixedButton';
import Popup from '../components/common/Popup';
import TermsCondsModal from '../components/modals/TermsCondsModal';
// import FlowersTypesMoadal from '../components/modals/FlowersTypesModal';
//service
import DesignService from '../services/DesignService';
//actions
import { setLoading } from '../store/actions/AppActions';
//toast
import {toast} from 'react-toastify';
//constant
import {imageTypes} from '../constants/Helpers';

function AddDesign({location}) {
  const t = useSelector((state) => getLangJson({ state })),
        lang = useSelector((state) => getLang({ state })),
        [name, setName] = useState(''),
        // [types, setTypes] = useState([]),
        [extra, setExtra] = useState(''),
        [images, setImages] = useState([]),
        [isOpen, handleToggle] = useState(false),
        [imgChanged, setImgChanged] = useState(false),
        [disabled, setDisabled] = useState(true),
        [agree, setAgree] = useState(false),
        dispatch = useDispatch(),
        history = useHistory();

  const validateImage = (img) => {
    return imageTypes.includes(img.type);
  };
  
  const addImage = async (img) => {
    let newImages = images;
    if(validateImage(img)){
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      try {
        const compressedFile = await imageCompression(img, options);
        newImages.push(compressedFile);
      } catch (error) {
        newImages.push(img);
      }
      setImages(newImages);
      setImgChanged(!imgChanged);
      checkValidation("image", newImages);
    }else{
      toast.error(lang==="ar"? "يمكنك رفع صور فقط" :"You can upload images only.")
    }
  };

  const deleteImage = (img) => {
    let oldImages = images;
    let newImages = oldImages.filter(function(i){
      return i.name !== img.name
    })
    setImages(newImages);
    checkValidation("image", newImages);
  };

  const addDesign = async () => {
    let data = {
      name: name,
      // flowerTypes: [{picture: '', name: 'test', count: 1}],
      Description: extra,
      images: images,
      terms: agree,
    };
    var form_data = new FormData();
    for ( var key in data ) {
        if(data[key] !== null ){
          if(Array.isArray(data[key])){
            if( key === "images"){
              for ( var imgkey in data[key] ) {
                form_data.append(`images[${imgkey}]`, data[key][imgkey]);
              }
            }else{
              for ( var typekey in data[key] ) {
                for (var propType in data[key][typekey]){
                  form_data.append(`flowerTypes[${typekey}][${propType}]`, data[key][typekey][propType]);
                }
              }
            }
          }else{
            form_data.append(key, data[key]);
          }
        }
    }
    dispatch(setLoading(true));
    try {
      const res = await DesignService.addDesign(form_data);
      toast.success(res?.data?.message?? "Design has been added successfully!");
      dispatch(setLoading(false));
      history.goBack();
    } catch (err) {
      dispatch(setLoading(false));
      toast.error(err?.response?.data?.message?? "Adding design has failed");
    }
  };

  // const deleteType = (type) => {
  //   let oldTypes = types;
  //   let newTypes = oldTypes.filter(function(t){
  //     return t.name !== type.name
  //   })
  //   setTypes(newTypes);
  //   checkValidation("type", newTypes);
  // };

  const checkValidation = (typeName, typeValue) => {
    // if((typeName === "name"? typeValue !== "" : name !== "") && (typeName === "type"? typeValue.length > 0 : types.length > 0) && (typeName === "image"? typeValue.length > 2 : images.length > 2)){
    if((typeName === "name"? typeValue !== "" : name !== "") && (typeName === "image"? typeValue.length > 2 : images.length > 2) && (typeName === "agree"? typeValue : agree)){
      setDisabled(false);
    }else{
      setDisabled(true);
    }
  };

  useEffect(() => {
    checkValidation('agree', agree);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agree])

  return (
    <>
      <div className="form-container">
        {/* <Popup 
          isOpen={isOpen} 
          handleToggle={handleToggle} 
        >
          <FlowersTypesMoadal types={types} setTypes={setTypes} handleToggle={handleToggle} isOpen={isOpen} checkValidation={checkValidation}/>
        </Popup> */}
        <Popup 
          isOpen={isOpen} 
          handleToggle={handleToggle} 
          height={'100%'}
          noradius
        >
          <TermsCondsModal handleToggle={handleToggle} setAgree={setAgree}/>
        </Popup>
        <p className="medium-txt sz-16 premium-black">{t.addDesign.subtitle}</p>
        <div className="light-blue-note design-note">
          <ul>
            {
              t.addDesign.notes().map(function(n, i){
                return(
                  <li key={i} className="regular-txt sz-12 title-color white-space line-h-24" dangerouslySetInnerHTML={{__html: n}} />
                )
              })
            }
          </ul>
        </div>
        <div className="spliter"/>
        <form className="design-form">
        <div className="input-container">
          {name !== '' && (<label htmlFor="name" className="custom-label medium-txt sz-10 primary-color">{t.addDesign.name}</label>)}
          <input id="name" className={`custom-input ${name !== ''? 'input-with-label' : ''}`} placeholder={t.addDesign.name} defaultValue={name} onChange={(e) => {setName(e.target.value);checkValidation("name",e.target.value);}} />
        </div>
        {/* <p className="medium-txt sz-16 title-color flower-type-title">{t.addDesign.type}</p>
        <p className="regular-txt sz-12 subtitle-color flower-type-subtitle">{t.addDesign.typeDesc}</p>
        {types.length > 0 && (
          <div className="types-options-container">
            {
              types.map(function(t, i){
                return(
                  <div className="type-container" key={i}>
                    <p className="regular-txt sz-12 flower-type-option">{t.count}{' '}{lang === "ar"? t.nameAr : t.name}</p>
                    <SmallClose className="close-option" onClick={() => deleteType(t)} />
                  </div>
                )
              })
            }
          </div>
        )}
        <p className="medium-txt sz-14 primary-color add-type-title" onClick={() => handleToggle(true)}>{t.addDesign.addType}</p> */}
        <div className="input-container">
          {extra !== '' && (<label htmlFor="extra" className="custom-label medium-txt sz-10 primary-color">{t.addDesign.extraDescLabel}</label>)}
          <textarea id="extra" className={`custom-input custom-textarea ${extra !== ''? 'input-with-label' : ''}`} placeholder={t.addDesign.extraDesc} defaultValue={extra} onChange={(e) => setExtra(e.target.value)} />
        </div>
        <div className="img-upload-container">
          <p className="medium-txt sz-16 title-color upload-img-title">{t.addDesign.upload}</p>
          <p className="regular-txt sz-12 title-color upload-img-subtitle">{t.addDesign.uploadNote}</p>
          <div className="uploaded-img-container">
            {
              images.map(function(img, i){
                return(
                  <div key={i} className="design-img-option" style={img?{backgroundImage: `url(${URL.createObjectURL(img)})`}:{}}>
                    <DeleteIcon className="red-delete" onClick={() => deleteImage(img)} />
                  </div>
                )
              })
            }
            <div className="add-img">
              <input type="file" accept="image/*" className="file" id="newImg" onChange={(e) => e.target.files[0]? addImage(e.target.files[0]) : toast.error(lang==="ar"? "تم الإلغاء" :"Cancelled")} />
              <label htmlFor="newImg">
                <AddImg/>
              </label>
            </div>
          </div>
        </div>
        <div className="yellow-note design-note">
          <div>
            <div>
              <label className="checkbox-container">
                <input type="checkbox" checked={agree} onChange={(e) => setAgree(e.target.checked)}/>
                <span className="checkmark"></span>
              </label>
              <p className="medium-txt sz-14 primary-color white-space line-h-20 p-m-0 p-l-28" onClick={() => handleToggle(true)}>{t.addDesign.tcTitle}</p>
            </div>
            <p className="regular-txt sz-14 premium-black white-space line-h-20 p-m-0 p-l-28">{t.addDesign.tcDesc}</p>
          </div>
        </div>
      </form>
      </div>
      <FixedButton disabled={disabled} handleClick={addDesign} title={t.addDesign.submit} />
    </>
  );
}

export default AddDesign;