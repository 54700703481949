import React, {useState} from 'react';
import {
  Switch,
  Route,
  useHistory,
  useLocation
} from "react-router-dom";
import { routes } from "./routes/RoutesConfig";
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import Loader from './components/common/Loader';
//svg
import {ReactComponent as Back} from './assets/images/back.svg';
//selectors
import { getLangJson, getLang } from './store/selectors/LangSelector';
import { getIsMobile } from './store/selectors/AppSelector';
//actions
import { setLang } from './store/actions/LangActions';
import { setHasJoined, setToken, setIsMobile } from './store/actions/AppActions';

function App() {
  const t = useSelector((state) => getLangJson({ state })),
        lang = useSelector((state) => getLang({ state })),
        isMobile = useSelector((state) => getIsMobile({ state })),
        [isLoading, setIsLoading] = useState(true),
        history = useHistory(),
        location = useLocation(),
        dispatch = useDispatch();

  const handleBack = () => {
    history.goBack();
  };

  window.addEventListener('message', (event) => {   
    if(event.data && event.data.key) {
        let key = event.data.key.toLowerCase();
        switch(key) {
          case  'addbackbtn':
            dispatch(setIsMobile(false));
          break;
          case 'hastoken':
            const CryptoJS = require("crypto-js");
            var bytes  = CryptoJS.AES.decrypt(event.data.token, 'cG9Mf7zPAv8gmqFbZKt9HSVKuUWQ4xP3');
            var originalText = bytes.toString(CryptoJS.enc.Utf8);
            dispatch(setToken(originalText));
          break;
          case 'hasjoined':
            if(event.data.hasJoined)
              dispatch(setHasJoined(event.data.hasJoined === "true" ? true : false));
            setIsLoading(false);
          break;
          case 'lang':
            dispatch(setLang(event.data.lang.toString()));
          break;
          default:
          break;
        }
    }
  });

  return (
    <div className={`${lang === "ar"? "App ar-app" : "App"} ${isLoading? "App-loading" : ""}`} data-testid="ApptestId">
      {isLoading?
        <Loader heightval={300}/>
      :
        <>
          {(location.pathname !== "/" && location.pathname !== "/how-it-works") && (
            <div className="top-header" style={isMobile? {display: "none"} : null}>
              <Back className="back-icon" onClick={handleBack}/>
              <p className="medium-txt sz-16">
                {location.pathname === "/add-bank-details"? 
                  t.addBankDetails.title 
                :
                  location.pathname === "/add-design"? 
                    t.landing.title 
                  :
                    location.pathname === "/designs"? 
                      t.designs.title 
                    :
                      location.pathname === "/transactions-history"? 
                        t.transactionHistory.title 
                      :
                        t.landing.title 
                }
              </p>
            </div>
          )}
          <div className={`${(location.pathname === "/" || location.pathname === "/how-it-works")? "" : "big-container"} ${location.pathname === "/designs"? "design-big-container": ""} ${isMobile? "mobile-container" : ""} ${(location.pathname === "/add-design" || location.pathname === "/add-bank-details" || location.pathname === "/transactions-history")? "no-side-padding" : ""}`}>
            <div className="maxwidth-container">
              <Switch>
                {routes.map((el, i) => (
                  <Route
                    path={el.path()}
                    render={(propRouter) => <el.Component {...propRouter} />}
                    key={i}
                  />
                ))}
              </Switch>
            </div>
            <ToastContainer />
          </div>
        </>
      }
    </div>
  );
}

export default App;
