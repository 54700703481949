//services
import { gatewayService } from './HttpService';
//end points
import { transferEndPoint } from './EndPoints';

class TransactionService {
	static getAllTransactionsHistory(params) {
		return gatewayService({
			method: 'GET',
			url: transferEndPoint(),
      params
		});
	}
  static transfer() {
		return gatewayService({
			method: 'POST',
			url: transferEndPoint(),
		});
	}
}

export default TransactionService;