import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
//selectors
import { getLangJson } from '../../store/selectors/LangSelector';
//svg
import {ReactComponent as Flower} from '../../assets/images/flower.svg';

function HowItWorksCard({location}) {
  const t = useSelector((state) => getLangJson({ state })),
        history = useHistory();

  return (
    <div className="card howItWorks-card" onClick={() => history.push('/how-it-works')}>
      <Flower className="howItWorks-icon"/>
      <p className="howItWorks-title sz-24 medium-txt">{t.landing.howItWorks}</p>
      <p className="howItWorks-link sz-13 medium-txt">{t.home.learnMore}</p>
    </div>
  );
}

export default HowItWorksCard;