import axios from "axios";
import { getToken } from '../store/selectors/AppSelector';
import { getLang } from '../store/selectors/LangSelector';
import { store } from '../store/store';

const isDevelopment = process.env.NODE_ENV === 'development';

const createNewBaseUrl = (url) =>
  axios.create({
    baseURL: url,
  });

const apiService = createNewBaseUrl(process.env.REACT_APP_API_URL);
const catalogService = createNewBaseUrl(process.env.REACT_APP_CATALOG_API_URL);
const gatewayService = createNewBaseUrl(process.env.REACT_APP_GATEWAY_API_URL);

const requestInterceptor = (config) => {
  config.headers["Authorization"] = `Bearer ${getToken({ state: store.getState() })}`;
  config.headers["lang"] = `${getLang({ state: store.getState() }) === 'ar'? 0 : 1}`;
  return config;
};

const requestInterceptorError = (error) => {
  if(isDevelopment){
    console.log("there is a request error", error);
  }
  return Promise.reject(error);
};

const responseInterceptor = (response) => {
  return response;
};

const responseInterceptorError = (error) => {
  const errorResponse = error.response;
  if(isDevelopment){
    console.log("response interceptor error:", errorResponse);
  }
  return Promise.reject(error);
};

const addInterceptors = (service) => {
  service.interceptors.request.use(requestInterceptor, requestInterceptorError);
  service.interceptors.response.use(
    responseInterceptor,
    responseInterceptorError
  );
};

addInterceptors(apiService);
addInterceptors(catalogService);
addInterceptors(gatewayService);

export { apiService, catalogService, gatewayService };
