class CommunicationService {
  static sendMessageToApp (data) {
    if (window?.parent?.postMessage) {
      window.parent.postMessage(data, '*');
    }
  }

  static joinButtonClicked (data = {}) {
    try {
      this.sendMessageToApp(data);
      // for Android, they use JS Interface from Webview
      if (window?.FloristAppJSInterface?.joinButtonClicked) {
        window.FloristAppJSInterface.joinButtonClicked(data);
      }
    } catch (error) {
      console.log(error);
    }
  }
}

export default CommunicationService;
