import CryptoJS from 'crypto-js';

const secretKey = 'we are the best of the best';

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const arMonths = [
  "يناير",
  "فبراير",
  "مارس",
  "إبريل",
  "مايو",
  "يونيو",
  "يوليو",
  "أغسطس",
  "سبتمبر",
  "أكتوبر",
  "نوفمبر",
  "ديسمبر"
];

export const updateObject = (oldObject, UpdatedValues) => {
	return {
		...oldObject,
		...UpdatedValues,
	};
};

export const decodeToken = (token) => {
	return JSON.parse(atob(token.split('.')[1]))
};

export const getQueryParams = (params) => {
	const queryParams = new URLSearchParams(params),
		paramsObj = {};

	for (let [key, value] of queryParams.entries()) {
		paramsObj[key] = value;
	}
	return paramsObj;
};

export const encryptData = (data) => {
	return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

export const decryptData = (data) => {
	const bytes = CryptoJS.AES.decrypt(data, secretKey);
	return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

export const formatDate = (date,lang) => {
  const dateFormat = new Date(date);
	let dd = String(dateFormat.getDate()).padStart(2, '0'),
		M = lang === "ar" ? arMonths[dateFormat.getMonth()] : months[dateFormat.getMonth()],
		yyyy = dateFormat.getFullYear(),
		newDate = dd + ' ' + M + ' ' + yyyy;
	return newDate;
};

export const formatDateWithoutDay = (date,lang) => {
  const dateFormat = new Date(date);
	let M = lang === "ar" ? arMonths[dateFormat.getMonth()] : months[dateFormat.getMonth()],
		yyyy = dateFormat.getFullYear(),
		newDate = M + ' ' + yyyy;
	return newDate;
};

export const formatDateddMMyyyy = (date) => {
  const dateFormat = date? new Date(date) : new Date();
	let dd = String(dateFormat.getDate()).padStart(2, '0'),
		MM = String(dateFormat.getMonth()+1).padStart(2, '0'),
		yyyy = dateFormat.getFullYear(),
		newDate = dd + '/' + MM + '/' + yyyy;
	return newDate;
};

export const changeDateWithMonths = (date, months, type) => {
  var d = date.getDate();
  if(type === "minus"){
    date.setMonth(date.getMonth() - +months);
  }else{
    date.setMonth(date.getMonth() + +months);
  }
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
};

export const imageTypes = [
  "image/apng",
  "image/bmp",
  "image/gif",
  "image/jpeg",
  "image/pjpeg",
  "image/png",
  "image/svg+xml",
  "image/tiff",
  "image/webp",
  "image/x-icon"
];

export const completeMonthData = (res,fromDate) => {
  const dayofNextDate = new Date(new Date(fromDate).setDate(new Date(fromDate).getDate() - 1));
  let toDate = new Date(changeDateWithMonths(new Date(dayofNextDate),1,"add"));
  let startDate = new Date(new Date(new Date(new Date(res[0].date).setDate(new Date(fromDate).getDate())).setMonth(new Date(fromDate).getMonth())).setFullYear(new Date(fromDate).getFullYear()));
  let newRes = [];
  res.map(function(r){
    while(startDate < r.date){
      newRes.unshift({count: 0, total: 0, date: startDate});
      startDate = new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1));
    }
    if(startDate.toISOString() === r.date.toISOString()){
      startDate = new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1));
    }
  })
  let lastDate = res[res.length - 1].date;
  while( lastDate < toDate){
    lastDate = new Date(new Date(lastDate).setDate(new Date(lastDate).getDate() + 1));
    newRes.unshift({count: 0, total: 0, date: lastDate});
  }
  res = res.concat(newRes);
  return res;
}

export const completeDayData = (res,fromDate) => {
  let startDate = new Date(fromDate.setHours(0));
      startDate = new Date(startDate.setMinutes(0));
      startDate = new Date(startDate.setSeconds(0));
  let toDate = new Date(fromDate.setHours(23));
      toDate = new Date(toDate.setMinutes(0));
      toDate = new Date(toDate.setSeconds(0));
  let newRes = [];
  res.map(function(r){
    while(startDate < r.date){
      newRes.unshift({count: 0, total: 0, date: startDate});
      startDate = new Date(new Date(startDate).setHours(new Date(startDate).getHours() + 1));
    }
    if(startDate.toISOString() === r.date.toISOString()){
      startDate = new Date(new Date(startDate).setHours(new Date(startDate).getHours() + 1));
    }
  })
  let lastDate = res[res.length - 1].date;
  while( lastDate < toDate){
    lastDate = new Date(new Date(lastDate).setHours(new Date(lastDate).getHours() + 1));
    newRes.unshift({count: 0, total: 0, date: lastDate});
  }
  res = res.concat(newRes);
  return res;
}

export const completeWeekData = (res,fromDate) => {
  const dayofNextDate = new Date(new Date(fromDate).setDate(new Date(fromDate).getDate() + 6));
  let toDate = new Date(dayofNextDate);
  let startDate = new Date(new Date(new Date(new Date(res[0].date).setDate(new Date(fromDate).getDate())).setMonth(new Date(fromDate).getMonth())).setFullYear(new Date(fromDate).getFullYear()));
  let newRes = [];
  res.map(function(r){
    while(startDate < r.date){
      newRes.unshift({count: 0, total: 0, date: startDate});
      startDate = new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1));
    }
    if(startDate.toISOString() === r.date.toISOString()){
      startDate = new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1));
    }
  })
  let lastDate = res[res.length - 1].date;
  while( lastDate < toDate){
    lastDate = new Date(new Date(lastDate).setDate(new Date(lastDate).getDate() + 1));
    newRes.unshift({count: 0, total: 0, date: lastDate});
  }
  res = res.concat(newRes);
  return res;
}

export const completeYearData = (res,fromDate) => {
  let startDate = new Date(new Date(new Date(new Date(res[0].date).setDate(new Date(fromDate).getDate())).setMonth(new Date(fromDate).getMonth())).setFullYear(new Date(fromDate).getFullYear()));
  const dayofNextDate = new Date(changeDateWithMonths(fromDate,11,"add"));
  let toDate = new Date(dayofNextDate);
  let newRes = [];
  res.map(function(r){
    while(startDate < r.date){
      newRes.unshift({count: 0, total: 0, date: startDate});
      startDate = new Date(new Date(startDate).setMonth(new Date(startDate).getMonth() + 1));
    }
    if(startDate.toISOString() === r.date.toISOString()){
      startDate = new Date(new Date(startDate).setMonth(new Date(startDate).getMonth() + 1));
    }
  })
  let lastDate = res[res.length - 1].date;
  while( lastDate < toDate){
    lastDate = new Date(new Date(lastDate).setMonth(new Date(lastDate).getMonth() + 1));
    newRes.unshift({count: 0, total: 0, date: lastDate});
  }
  res = res.concat(newRes);
  return res;
}