//services
import { apiService } from './HttpService';
//end points
import { joinEndPoint, latestBouquetEndPoint, balanceEndPoint, salesReportDayEndPoint, salesReportWeekEndPoint, salesReportMonthEndPoint, salesReportYearEndPoint } from './EndPoints';

class HomeService {
	static joinFC() {
		return apiService({
			method: 'POST',
			url: joinEndPoint(),
		});
	}

  static getLatestBouquetData() {
		return apiService({
			method: 'GET',
			url: latestBouquetEndPoint(),
		});
	}

  static getBalanceData() {
    return apiService({
			method: 'GET',
			url: balanceEndPoint(),
		});
  }

  static getSalesReportDayData(params) {
    return apiService({
			method: 'GET',
			url: salesReportDayEndPoint(),
      params
		});
  }

  static getSalesReportWeekData(params) {
    return apiService({
			method: 'GET',
			url: salesReportWeekEndPoint(),
      params
		});
  }

  static getSalesReportMonthData(params) {
    return apiService({
			method: 'GET',
			url: salesReportMonthEndPoint(),
      params
		});
  }

  static getSalesReportYearData(params) {
    return apiService({
			method: 'GET',
			url: salesReportYearEndPoint(),
      params
		});
  }
}

export default HomeService;
