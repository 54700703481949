import { GET_LATEST_BOUQUET, GET_BALANCE, GET_MONTH_SALES_REPORT, GET_SALES_REPORT } from '../types/HomeTypes';
//constants
import { updateObject } from '../../constants/Helpers';

const initialState = {
	latestBouquet: {},
  balance: {balance: 0},
  salesReport: {data: [], totalOrders: 0, totalCommission: 0},
  monthSalesReport: {data: [], totalOrders: 0, totalCommission: 0},
};

const HomeReducer = (state = initialState, action) => {
	switch (action.type) {
    case GET_LATEST_BOUQUET: {
      return updateObject(state, { latestBouquet: action.latestBouquet });
    }
    case GET_BALANCE: {
      return updateObject(state, { balance: action.balance });
    }
    case GET_MONTH_SALES_REPORT: {
      return updateObject(state, { monthSalesReport: action.monthSalesReport });
    }
    case GET_SALES_REPORT: {
      return updateObject(state, { salesReport: action.salesReport });
    }
    default:
      return state; 
	}
};

export default HomeReducer;