export const en = {
	landing: {
		title: 'Floward Community',
		desc: 'We are inviting you to contribute your designs to our product selection across all cities Floward operates in. Prepare your arrangements, submit your designs, and upon approval, start earning with every sale!',
		howItWorks: 'How it works',
		landingSubtitle: 'Three steps to be one of our florist',
		step1Title: 'Prepare',
		step1Desc: `Design and arrange your bouquet at home. Take photos of your arrangement in good lighting.`,
		step2Title: 'Upload',
		step2Desc: `Upload at least three photos of your design from different angles. Include details of flowers used and other items.`,
		step3Title: 'Approval process',
		step3Desc: `We will review your designs to check if they match our guidelines and terms & conditions.`,
		step4Title: 'Get your commission',
		step4Desc: `For every approved product sold on Floward, you will receive a portion of the arrangements’ sales as a commission fee.\n\nYou will get a monthly sales report via email.\n\nFloward will have full discretion on the prices for all arrangements.`,
		joinBtn: 'Next',
	},
	home: {
		addDesign: 'Submit a design',
		availableBalance: 'Available balance',
		transfer: 'Transfer',
		transferTo: 'Transferring to',
		addBankDetails:
			'Add your bank details to receive the commissions from the sales of your designs',
		addBankDetailsBtn: 'Add Bank Account',
		bankName: 'Bank Name:',
		accountNo: 'Account No:',
		changeBankDetails: 'Change bank details',
		salesReport: 'Sales report',
		details: 'Details',
		reportTitle: 'Your sales of last 30 days',
		totalOrders: 'Total orders',
		yourCommission: 'Your commission',
		orders: 'Orders',
		learnMore: 'Learn More',
		yourDesign: 'Your Designs',
		seeAll: 'See All',
		underReview: 'Under review',
		accepted: 'Accepted',
		rejected: 'Rejected',
		requestId: 'Request ID:',
		requestDate: 'Request date:',
	},
	addBankDetails: {
		title: 'Add Bank Account',
		bankNote: 'Add your bank details to receive the commissions from the sales of your designs',
		yourBankDetails: 'Your bank account details',
		nameLabel: 'Name of the account holder',
		bankLabel: 'Select bank name',
		accountNoLabel: 'Account Number',
		ibanLabel: 'IBAN number',
		save: 'Save',
	},
	requestStatus: {
		title: 'Status',
		underReviewMsg: `Your design is currently under review.`,
		underReviewDesc: 'You will receive an email with the status of your submission once a decision has been made.',
		rejectedMsg: 'Sorry! Your design was rejected.',
		rejectedDesc: `Unfortunately, we will not be able to move forward with your submission, because it does not abide by the outlined criteria for selection in the terms and conditions. You can submit new designs at any time`,
		acceptedMsg: 'Congratulations!\nYour design has been selected!',
		acceptedDesc: `Please check your email for more information.`,
	},
	addDesign: {
		title: 'Your design details',
		subtitle: 'Upload Your Design',
		notes: () => [
			'Provide details for just one design per time.',
			'Add all the flowers types used to create this arrangement and their quantities.',
			'Upload at least 3 images of your design from different angles.',
			'Enter accessories details and describe the packaging.',
		],
		name: 'Name your design',
		type: 'Flower types',
		typeDesc: 'Select all flowers type you used in this design.',
		addType: '+ Add flower type',
		extraDescLabel: 'Details:',
		extraDesc: 'Include details like flower types, number of stems, vase, etc.',
		upload: 'Upload images of your bouquet',
		uploadNote: 'Upload several images for the design from different angles minimum of 3 images.',
		tcTitle: 'T&Cs',
		tcDesc: 'I agree to the terms and conditions',
		submit: 'Submit',
	},
	terms: {
		title: 'Terms & Conditions',
    subTitle: 'Floward Community',
		desc: () => [
			`<b>About Floward Community</b>\nWe are inviting local florists to contribute to our products with their designs across all cities Floward operates in. The idea behind Floward Community is to give exposure to local Florists in the countries where Floward operates and to create a positive impact by increasing local engagement with the florists who will feel supported by Floward and take a sense of pride by seeing their floral designs and creations showcased and sold on our platforms.`,
      `<b>Purpose of Floward Community</b>\nThe purpose of the FLoward Community feature is to allow individual amateur Florists to showcase their designs on the Floward application and website. Upon approval of his or her submission(s), the Florist will be entitled to a royalty for each sale of their product. The product will remain in Floward’s ownership. We create the product as per the design submitted to us by the Florist and dispatch the arrangement similarly to the way we do with any other product on our website.`,
      `<b>Evaluation</b>\n<ul><li>Applicant to submit 1 design at a time.</li><li>Provide high-resolution photos of the arrangements and detailed description of the materials used as follows:<ul><li>	The types of flowers that go in the arrangement and the quantities along with suppliers’ details.</li><li>	Accessories description with suppliers’ details.</li><li>	Specific box designs and their sizes & material.</li></ul></li><li>Floward will select and approve designs by florists and showcase them in a specific category related to “Floward Community” for a minimal duration of 3 months and which can extend at Floward’s discretion.</li></ul>`,
      `<b>Pricing of arrangements and Commissions owed to Florists</b>\n<ul><li>Floward will have full discretion on the prices for all arrangements and on the names given to the arrangements by the florists. Once the arrangement is approved, Floward will notify the florist with the name and the price of the arrangement on the website.</li><li>For every approved product sold on Floward, the florists will receive a royalty of the selected arrangements’ sales as a commission fee. This will be communicated to the florists upon approval.</li><li>The florist will receive a monthly sales report and settlement of balance due will be made 15 days after the end of every month (subject to reaching the minimum amount, set out in the detailed agreement).</li><li>In the event that the accumulated balance due to the florist does not exceed a minimum amount of $1000 in any given month, no sum will be transferred until such amount has been exceeded.</li><li>In the event a Florist’s arrangement is selected, then said Florist shall sign a separate designer agreement with Floward with specific terms and conditions which shall prevail in case of contradiction with the present terms.</li></ul>`,
      `<b>Intellectual Property</b><ul><li>All designs produced by florists shall not bear the name of the florists and will be the sole property of Floward.</li><li>Any new box design is the property of Floward.</li><li>The arrangements created for Floward cannot be replicated outside or given to any other platform.</li></ul>`,
      `<b>Criteria for Selection</b>\n<ul><li>Flowers used in the arrangement must be fresh flowers, no artificial flowers.</li><li>Fresh flowers must not be spray painted or artificially coloured.</li><li>The arrangement must maintain a reasonable flower-to-vase ratio, to allow for safe handling.</li><li>The arrangements cannot be replicas of existing arrangements on Floward or elsewhere.</li><li>Provide minimum 4 clear images of the arrangement from different angles, including:<ul><li>Full wide shot of the arrangement from the front.</li><li>Close up details of the flowers.</li></ul></li></ul>`,
      `<b>Disclaimer and Exclusions</b>\n<ul><li>Availability of the arrangements is subject to Floward local inventory availability.</li><li>Participation in the florists community is not open to employees of Floward or members of the their immediate family (parents, children, siblings, spouse (and spouse’s immediate family).</li></ul>`,
      `<b>Data Protection</b>\nAny personal information that florists share with Floward will be kept secure. By participating in the Florists Community, florists agree that their information may be used by Floward to administer the Florist Community and consent to the use of their personal information for any other purpose set out in the Floward Privacy Policy`,
		],
    btnTxt: 'I agree',
	},
	designs: {
		title: 'Designs',
		emptyUR: `There are no  design requests.\n\nSubmit your designs today!`,
		emptyRj: 'You have no rejected designs.',
		emptyAc: 'You have no approved designs yet.',
		urTab: 'Under Review',
		acTab: 'Accepted',
		rjTab: 'Rejected',
	},
	flowersType: {
		search: 'Search',
		flowersType: 'Add flower type',
	},
	flowersQty: {
		selectQty: 'Select Quantity',
		numberOfFlowers: (type) => `Specify the number of ${type}`,
		confirm: 'Confirm',
	},
	salesReport: {
		allBouquet: 'All bouquets',
		selectBouquet: 'Select bouquet',
		day: 'Day',
		week: 'Week',
		month: 'Month',
		year: 'Year',
	},
	transactionHistory: {
		title: 'Balance',
		balanceNote1: 'The available balance for all submitted arrangements',
		transactionHistory: 'Transactions History',
		transfered: 'Transfer to bank',
		transferedOn: 'Transferred on',
	},
};
