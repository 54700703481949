export const joinEndPoint = () => 'join';

export const latestBouquetEndPoint = () => 'bouquets/latest';

export const bankDetailsEndPoint = () => 'bankdetails';

export const balanceEndPoint = () => 'user/balance';

export const designsEndPoint = () => 'bouquets';

export const designsByIdEndPoint = (id) => `bouquets/${id}`;

export const flowerTypesEndPoint = () => 'flower/types';

export const salesReportDayEndPoint = () => 'reports/daily';

export const salesReportWeekEndPoint = () => 'reports/weekly';

export const salesReportMonthEndPoint = () => 'reports/monthly';

export const salesReportYearEndPoint = () => 'reports/yearly';

export const transferEndPoint = () => 'community/withdraws';