import { 
  SET_SELECTED_DESIGN, 
  GET_ALL_DESIGNS, 
  GET_FLOWER_TYPES, 
  SET_SELECTED_FLOWER_TYPE,
  GET_UNDERREVIEW_DESIGNS,
  GET_ACCEPTED_DESIGNS,
  GET_REJECTED_DESIGNS,
  SET_FLOWER_TYPES_PAGE_COUNT,
  SET_UNDERREVIEW_DESIGNS_PAGE_COUNT,
  SET_ACCEPTED_DESIGNS_PAGE_COUNT,
  SET_REJECTED_DESIGNS_PAGE_COUNT,
} from '../types/DesignTypes';
//constants
import { updateObject } from '../../constants/Helpers';

const initialState = {
	design: {},
  allDesigns: [],
  flowerTypes: [],
  flowerTypesPageCount: 1,
  selectedFlowerType: {},
  underReviewDesigns: [],
  underReviewDesignsPageCount: 1,
  acceptedDesigns: [],
  acceptedDesignsPageCount: 1,
  rejectedDesigns: [],
  rejectedDesignsPageCount: 1
};

const DesignReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_DESIGN: {
      return updateObject(state, { design: action.design });
    }
    case GET_ALL_DESIGNS: {
      return updateObject(state, { allDesigns: action.allDesigns });
    }
    case GET_FLOWER_TYPES: {
      return updateObject(state, { flowerTypes: action.flowerTypes });
    }
    case SET_FLOWER_TYPES_PAGE_COUNT: {
      return updateObject(state, { flowerTypesPageCount: action.flowerTypesPageCount });
    }
    case SET_SELECTED_FLOWER_TYPE: {
      return updateObject(state, { selectedFlowerType: action.selectedFlowerType });
    }
    case GET_UNDERREVIEW_DESIGNS: {
      return updateObject(state, { underReviewDesigns: action.underReviewDesigns });
    }
    case SET_UNDERREVIEW_DESIGNS_PAGE_COUNT: {
      return updateObject(state, { underReviewDesignsPageCount: action.underReviewDesignsPageCount });
    }
    case GET_ACCEPTED_DESIGNS: {
      return updateObject(state, { acceptedDesigns: action.acceptedDesigns });
    }
    case SET_ACCEPTED_DESIGNS_PAGE_COUNT: {
      return updateObject(state, { acceptedDesignsPageCount: action.acceptedDesignsPageCount });
    }
    case GET_REJECTED_DESIGNS: {
      return updateObject(state, { rejectedDesigns: action.rejectedDesigns });
    }
    case SET_REJECTED_DESIGNS_PAGE_COUNT: {
      return updateObject(state, { rejectedDesignsPageCount: action.rejectedDesignsPageCount });
    }
		default:
			return state;
	}
};

export default DesignReducer;