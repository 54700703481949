export const ar = {
	landing: {
		title: 'عالم فلاورد',
		desc: `شارك بتصميماتك لأحد المنتجات، وسنقوم بعرضها على منصاتنا في كل المدن التي تتواجد بها فلاورد. قدم تصميمك، وعند الموافقة، ستبدأ في الربح مع كل عملية بيع!`,
		howItWorks: `كيف تعمل`,
		landingSubtitle: 'ثلاث خطوات لتصبح واحد من بائعي الورود',
		step1Title: `جهز التصميم`,
		step1Desc: `صمم وجهز الباقة في المنزل، قم بتصوير الباقة في اضاءة جيدة.`,
		step2Title: `ارفع الصور`,
		step2Desc: `ارفع على الأقل ثلاثة صور لتصميمك من عدة زوايا، وأيضاً اذكر تفاصيل الورد المستخدم وبقية العناصر الأخرى.`,
		step3Title: `عملية الموافقة`,
		step3Desc: `سنقوم بمراجعة التصميم للتأكد انه يناسب ارشاداتنا وأيضاً الشروط والأحكام.`,
		step4Title: `احصل على نسبتك`,
		step4Desc: `كل مرة يتم بيع باقة من تصميمك، ستحصل على جزء من المبيعات كعمولة.\n\nستحصل على تقرير شهري للمبيعات عبر البريد الالكتروني.\n\nتحتفظ فلاورد بخصوصية تقييم الاسعار لكل الباقات.`,
		joinBtn: `التالي`,
	},
	home: {
		addDesign: 'قدِّم تصميم',
		availableBalance: 'الرصيد المُتاح',
		transfer: 'تحويل',
		transferTo: 'تحويل إلى',
		addBankDetails: 'أضف حسابك البنكي لتتمكن من استقبال عمولتك من مبيعات تصميماتك',
		addBankDetailsBtn: 'أضف حساب بنكي',
		bankName: 'اسم البنك:',
		accountNo: 'رقم الحساب:',
		changeBankDetails: 'تغيير تفاصيل الحساب',
		salesReport: 'تقرير المبيعات',
		details: 'التفاصيل',
		reportTitle: 'تفاصيل المبيعات اخر 30 يوم',
		totalOrders: 'مجموع الطلبيات',
		yourCommission: 'نسبتك من المبيعات',
		orders: 'طلبيات',
		learnMore: 'تعلم المزيد',
		yourDesign: 'التصميمات الخاصة بك',
		seeAll: 'شاهد الكل',
		underReview: 'جاري المراجعة',
		accepted: 'تم قبول الطلب',
		rejected: 'تم رفض الطلب',
		requestId: 'رقم الطلب:',
		requestDate: 'تاريخ الطلب:',
	},
	addBankDetails: {
		title: 'أضف حساب بنكي',
		bankNote: 'أضف حسابك البنكي لتتمكن من استقبال عمولتك من مبيعات تصميماتك',
		yourBankDetails: 'تفاصيل حسابك البنكي',
		nameLabel: 'اسم صاحب الحساب',
		bankLabel: 'اختر اسم البنك',
		accountNoLabel: 'رقم الحساب',
		ibanLabel: 'رقم الحساب البنكي الدولي',
		save: 'حفظ',
	},
	requestStatus: {
		title: 'الحالة',
		underReviewMsg: 'جاري مراجعة التصميم الخاص بك',
		underReviewDesc: 'سوف تتلقى رسالة على بريدك بمجرد اتخاذ فريقنا للقرار',
		rejectedMsg: 'نأسف، تم رفض التصميم الخاص بك.',
		rejectedDesc: 'نعتذر، لن نستطيع قبول الطلب الخاص بك، لأنه لا يتفق مع معايير الاختيار الخاصة بنا، ولا الشروط والأحكام. يمكنك دوماً المحاولة مرة أخرى وتقديم تصميمات جديدة في أي قت.',
		acceptedMsg: 'تهانينا! تم قبول التصميم الخاص بك!',
		acceptedDesc: 'لقد ارسلنا بريد الكتروني بالمزيد من المعلومات.',
	},
	addDesign: {
		title: 'تفاصيل التصميم',
		subtitle: 'ارفع تصميمك',
		notes: () => [
			'قم بإضافة تفاصيل لتصميم واحد في كل مرة.',
			'أضف كل أنواع الأزهار المستخدمة في تصميم الباقة وأيضاً العدد.',
			`قم برفع ثلاثة صور على الأقل صور لتصميمك من عدة زوايا.`,
			'اذكر تفاصيل الإكسسوارات وقم بوصف التغليف',
		],
		name: 'اعط اسم للتصميم',
		type: 'أنواع الأزهار',
		typeDesc: 'اختر أنواع الزهور التي استخدمتها في التصميم',
		addType: '+ أضف نوع زهور',
		extraDescLabel: 'تفاصيل:',
		extraDesc: 'اذكر تفاصيل عن انواع الازهار المستخدمة، عدد الأزهار، التغليف، إلخ',
		upload: 'ارفع صور للباقة',
		uploadNote: 'ارفع اكتر من صورة لتصميمك من عدة زوايا الحد الأدنى ثلاثة صور.',
		tcTitle: 'الشروط والأحكام',
		tcDesc: 'موافق على الشروط والأحكام',
		submit: 'إرسال',
	},
	terms: {
		title: 'الشروط والأحكام',
    subTitle: 'عالم فلاورد',
		desc: () => [
			`<b>نبذة عن عالم فلاورد</b>\nندعو - من لديهم موهبة تنسيق الزهور - المحليين للمشاركة في منتجاتنا بتصاميمهم في جميع الدول التي تعمل فيها شركة فلاورد. وتتمثل فكرة عالم فلاورد في الترويج للمشاركين في الخاصية ، وإحداث تأثير إيجابي من خلال زيادة المشاركة المحلية مع منسقي الورد المحليين الذين سيشعرون بدعم فلاورد لهم، ويفخرون برؤية تصاميمهم وإبداعاتهم في تنسيق الزهور وبيعها على منصاتنا.`,
      `<b>هدف عالم فلاورد</b>\nيستهدف عالم فلاورد إتاحة فرصة لمنسقي الزهور الهواة لعرض تصاميمهم على تطبيق فلاورد وموقعها الإلكتروني. يحق لمنسق الزهور الحصول على عمولة مقابل كل عملية بيع لمنتجاته، وذلك بعد قبول التصميم/ التصميمات التي قدمها. لكن سيظل المنتج ملكًا لفلاورد. سيتم تنسيق المنتج طبقًا للتصميم الذي قدمه لنا منسق الزهور المحلي، وسنسلمه بالطريقة نفسها التي نتبعها لتسليم أي منتج آخر معروض على موقعنا الإلكتروني.`,
      `
      <b>التقييم</b>\n<ul><li>يجب على مقدم الطلب تقديم تصميم واحد في كل مرة.</li><li>يجب تقديم صور عالية الدقة للتنسيقات ووصف تفصيلي للمواد المستخدمة على النحو التالي:<ul><li>أنواع الزهور التي تدخل في التنسيق وكمياتها وتفاصيل الموردين.</li><li>	وصف الملحقات وتفاصيل الموردين.</li><li>	تصاميم الصناديق وأحجامها ومواد صنعها.</li></ul></li><li>تختار فلاورد تصاميم منسقي الزهور وتعتمدها وتعرضها في فئة محددة ضمن "عالم فلاورد" لمدة لا تقل عن 3 أشهر، ويمكن تمديد هذه المدة وفقًا لتقدير فلاورد.</li></ul>`,
      `<b>تسعير التنسيقات والعمولات المستحقة لمنسقي الزهور</b>\n<ul><li>ستحدد فلاورد وفقًا لتقديرها المطلق أسعار جميع التنسيقات، وستقيم الأسماء التي أطلقها منسقو الزهور عليها. وبمجرد الموافقة على التنسيق، تُخطر فلاورد منسق الزهور باسم التنسيق وسعره على الموقع الإلكتروني.</li><li>سيتقاضى منسقو الزهور عمولة بيع مقابل كل منتج معتمد ومباع على فلاورد، وتُرسل العمولات إلى بائعي الزهور فور اعتمادها.</li><li>يتلقى منسقو الزهور تقرير مبيعات شهريًا، وستُدفع مستحقاته عقب مرور 15 يومًا بعد نهاية كل شهر (بشرط بلوغ مبلغ الحد الأدنى المحددة تفصيلاً في الاتفاقية)</li><li>إذا لم يزد المبلغ المتراكم المستحق لمنسق الزهور عن مبلغ الحد الأدنى وهو 1000 دولار في أي شهر، فلن تُحوَل مستحقاته له حتى يتخطى هذا المبلغ.</li><li>في حال اختيار تنسيق لأي من منسقي الزهور، سيوقّع هذا البائع على اتفاقية مصمم منفصلة مع شركة فلاورد بشروط وأحكام محددة تسود في حالة التعارض مع الشروط الماثلة.</li></ul>
      `,
      `<b>حقوق الملكية الفكرية</b>\n<ul><li>جميع التصاميم التي يقدمها منسقو الزهور لن تحمل أسماءهم، وستكون ملكية خاصة لشركة فلاورد.</li><li>تعود ملكية تصميم أي صندوق جديد لشركة فلاورد.</li><li>لا يجوز تكرار التنسيقات المصممة لشركة فلاورد واستخدامها في أي مكان خارجها أو تقديمها إلى أي منصة أخرى.</li></ul>`,
      `<b>معايير الاختيار</b>\n<ul><li>يتعين أن تكون الزهور المستخدمة في التنسيق طبيعية وليست صناعية.</li><li>يتعين عدم طلاء الزهور الطبيعية باستخدام رذاذ أو ألوان صناعية.</li><li>يتعين أن يتناسب حجم التنسيق مع سعة المزهرية ليكون استخدامها آمنًا.</li><li>لا يجوز أن تتشابه التنسيقات مع تنسيقات أخرى معروضة على منصة فلاورد أو في أي مكان آخر.</li><li>يجب تقديم 4 صور واضحة على الأقل للتنسيق من زوايا مختلفة، بما في ذلك:<ul><li>صورة عريضة للباقة للتنسيق من الأمام.</li><li>صورة مقربة للباقة توضح تفاصيل الزهور.</li></ul></li></ul>
      `,
      `<b>بيان إخلاء المسؤولية والاستثناءات</b>\n<ul><li>يعتمد توافر التنسيقات على المتوفر في مخزون فلاورد المحلي.</li><li>لا تُتاح المشاركة في عالم فلاورد لموظفي فلاورد أو أقاربهم من الدرجة الأولى (الآباء والأبناء والأشقاء والأزواج (وكذلك أقارب الأزواج من الدرجة الأولى).</li></ul>`,
      `<b>حماية البيانات</b>\nسيتم الحفاظ على أمن أي معلومات شخصية يقدمها منسقو الزهور لفلاورد، ومن يشاركون في عالم فلاورد يُعتبرون موافقين على أنه يجوز لشركة فلاورد استخدام معلوماتهم لإدارة خاصية عالم فلاورد واستخدام معلوماتهم الشخصية لأي غرض آخر منصوص عليه في سياسة الخصوصية الخاصة بشركة فلاورد`
		],
    btnTxt: 'موافق',
	},
	designs: {
		title: 'التصميمات',
		emptyUR: `لا يوجد لديك أي طلب لتقديم تصميمات.\n\nقدِّم تصميمات الأزهار الخاصة بك اليوم!`,
		emptyRj: 'لا يوجد لديك تصميمات مرفوضة.',
		emptyAc: 'لا يوجد تصميمات تم قبولها بعد. ',
		urTab: 'جاري المراجعة',
		acTab: 'مقبولة',
		rjTab: 'مرفوضة',
	},
	flowersType: {
		search: 'بحث',
		flowersType: 'أضف نوع زهور',
	},
	flowersQty: {
		selectQty: 'حدد الكمية',
		numberOfFlowers: (type) => `حدد عدد ${type}`,
		confirm: 'تأكيد',
	},
	salesReport: {
		allBouquet: 'جميع الباقات',
		selectBouquet: 'اختر باقة',
		day: 'يوم',
		week: 'اسبوع',
		month: 'شهر',
		year: 'سنة',
	},
	transactionHistory: {
		title: 'Balance',
		balanceNote1: 'الرصيد المتاح من نسبة مبيعاتك',
		transactionHistory: 'تاريخ المعاملات',
		transfered: 'تحويل إلى حسابك',
		transferedOn: 'تحويل بتاريخ',
	},
};
