import { useSelector } from 'react-redux';
//selectors
import { getLangJson } from '../../store/selectors/LangSelector';
//svg
import { ReactComponent as TermsClose } from '../../assets/images/terms-close.svg';
//components
import FixedButton from '../common/FixedButton';

function TermsCondsModal({handleToggle, setAgree}) {
  const t = useSelector((state) => getLangJson({ state }));

  const handleAgree = () => {
    setAgree(true);
    handleToggle(false);
  }

  return (
    <>
      <div className="terms-conds-modal-container">
        <TermsClose className="close-icon" onClick={() => handleToggle(false)} />
        <p className="medium-txt sz-16 light-date p-m-0 text-center terms-title">{t.terms.title}</p>
        <div className="terms-conds-modal-body">
          <p className="medium-txt sz-16 light-date p-m-0 text-center terms-title">{t.terms.subTitle}</p>
          {
            t.terms.desc().map(function(n, i){
              return(
                <p key={i} className="regular-txt sz-12 subtitle-color white-space line-h-14" dangerouslySetInnerHTML={{__html: n}} />
              )
            })
          }
        </div>
      </div>
      <FixedButton handleClick={handleAgree} title={t.terms.btnTxt}></FixedButton>
    </>
  )
}

export default TermsCondsModal;