function Popup({ isOpen, handleToggle, children, height, noradius }) {

  return (
    <div className={`modal-overlay ${isOpen ? "show" : "hide"}`} onClick={() => handleToggle(false)}>
      <div className={`modal ${isOpen ? "show" : "hide"} ${noradius? "no-radius" : ""}`} style={height?{ height: height }:{}} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  )
}

export default Popup;