import { SET_BANK_DETAILS, GET_BANK_DETAILS, UPDATE_BANK_DETAILS } from '../types/BankTypes';
//actions
import { setLoading, setFetching } from './AppActions';
//service
import BankService from '../../services/BankService';
//toast
import { toast } from 'react-toastify';

export const setBankDetails = (data) => async (dispatch) => {
  dispatch(setLoading(true));
	try {
    const res = await BankService.addBankDetails(data);
    dispatch({
      type: SET_BANK_DETAILS,
      bankDetails: data,
    });
    dispatch(setLoading(false));
    toast.success(res?.data?.message?? "Bank details are added successfully!")
  } catch (err) {
      dispatch(setLoading(false));
      toast.error(err?.response?.data?.message);
  }
};

export const getBankDetails = () => async (dispatch) => {
  dispatch(setFetching(true));
	try {
    const res = await BankService.getBankDetailsData();
    dispatch({
      type: GET_BANK_DETAILS,
      bankDetails: res.data,
    });
    dispatch(setFetching(false));
  } catch (err) {
      dispatch(setFetching(false));
  }
};

export const updateBankDetails = (data) => async (dispatch) => {
  dispatch(setLoading(true));
	try {
    const res = await BankService.updateBankDetails(data);
    dispatch({
      type: UPDATE_BANK_DETAILS,
      bankDetails: data,
    });
    dispatch(setLoading(false));
    toast.success(res?.data?.message?? "Bank details are updated successfully!");
  } catch (err) {
      dispatch(setLoading(false));
      toast.error(err?.response?.data?.message);
  }
};