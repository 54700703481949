import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {Link} from "react-router-dom";
// import { changeDateWithMonths } from '../constants/Helpers';
//selectors
import { getLangJson } from '../store/selectors/LangSelector';
//components
import AddBouquetCard from '../components/home/AddBouquetCard';
// import BalanceCard from '../components/home/BalanceCard';
// import BankDetailsCard from '../components/home/BankDetailsCard';
// import SalesReportCard from '../components/home/SalesReportCard';
import HowItWorksCard from '../components/home/HowItWorksCard';
import DesignCard from '../components/design/DesignCard';
import Popup from '../components/common/Popup';
import StatusModal from '../components/modals/StatusModal';
// import SalesReportModal from '../components/modals/SalesReportModal';
//actions
// import { getBankDetails } from '../store/actions/BankActions';
import { getLatestBouquet } from '../store/actions/HomeActions';
// import { getBalance, getMonthSalesReport } from '../store/actions/HomeActions';
//selectors
import { getLatestBouquetData } from '../store/selectors/HomeSelector';
// import { getBalanceData, getMonthSalesReportData } from '../store/selectors/HomeSelector';
// import { getBankDetailsData } from '../store/selectors/BankSelector';

function Home({location}) {
  const t = useSelector((state) => getLangJson({ state })),
        [isOpen, handleToggle] = useState(false),
        // [isSalesReportOpen, handleSalesReportToggle] = useState(false),
        latestBouquet = useSelector((state) => getLatestBouquetData({ state })),
        // bankDetails = useSelector((state) => getBankDetailsData({ state })),
        // balance = useSelector((state) => getBalanceData({ state })),
        // monthSalesReport = useSelector((state) => getMonthSalesReportData({ state })),
        dispatch = useDispatch();

  useEffect(() => {
    dispatch(getLatestBouquet());
    // dispatch(getBankDetails());
    // dispatch(getBalance());
    // dispatch(getMonthSalesReport({day: new Date(new Date(changeDateWithMonths(new Date(),1,"minus")).setDate(new Date(changeDateWithMonths(new Date(),1,"minus")).getDate() + 1))}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      <Popup
        isOpen={isOpen}
        handleToggle={handleToggle}
      >
        <StatusModal handleToggle={handleToggle} />
      </Popup>
      {/* <Popup
        isOpen={isSalesReportOpen}
        handleToggle={handleSalesReportToggle}
      >
        <SalesReportModal isOpen={isSalesReportOpen} handleToggle={handleSalesReportToggle} />
      </Popup> */}
      <div className="flex-row">
        <p className="title-color sz-16 medium-txt yourdesign-title">{t.home.yourDesign}</p>
        <Link className="primary-color sz-12 regular-txt seeAll-link" to="/designs">{t.home.seeAll}</Link>
      </div>
      {Object.keys(latestBouquet).length>0 && (<DesignCard isClickable={true} data={latestBouquet} handleToggle={handleToggle} />)}
      <AddBouquetCard/>
      {/* <BalanceCard data={balance}/>
      <p className="medium-txt sz-16 title-color transferring-to-title">{t.home.transferTo}</p>
      <BankDetailsCard data={bankDetails} />
      <div className="flex-row">
        <p className="medium-txt sz-16 title-color transferring-to-title">{t.home.salesReport}</p>
        <p className="primary-color sz-12 regular-txt seeAll-link" onClick={() => handleSalesReportToggle(true)}>{t.home.details}</p>
      </div>
      <SalesReportCard data={monthSalesReport} /> */}
      <HowItWorksCard/>
    </>
  );
}

export default Home;