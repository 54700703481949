import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootReducer from './rootReducer';
//middlewares
import thunk from 'redux-thunk';

const isDevelopment = process.env.NODE_ENV === 'development',
	middlewares = [];

// log redux data in development mode only
// if (isDevelopment) {
// 	const { logger } = require('redux-logger');
// 	middlewares.push(logger);
// }

/* Create a Redux store that holds the app state. */
const configureStore = () => {
	const apply = applyMiddleware(thunk, ...middlewares);

	return createStore(
		rootReducer,
		/* preloadedState, */
		//use redux dev tool in development only
		isDevelopment ? composeWithDevTools(apply) : apply
	);
};

//used to access redux store outside of components
export const store = configureStore();
