import { SET_HAS_JOINED, SET_LOADING, SET_REPORT_FETCHING, SET_FETCHING, SET_TOKEN, SET_IS_MOBILE } from '../types/AppTypes';

export const setLoading = (load) => ({
	type: SET_LOADING,
	load,
});

export const setFetching = (fetching) => ({
	type: SET_FETCHING,
	fetching,
});

export const setReportFetching = (report_fetching) => ({
	type: SET_REPORT_FETCHING,
	report_fetching,
});

export const setHasJoined = (hasJoined) => ({
	type: SET_HAS_JOINED,
	hasJoined,
});

export const setToken = (token) => ({
	type: SET_TOKEN,
	token,
});

export const setIsMobile = (isMobile) => ({
	type: SET_IS_MOBILE,
	isMobile,
});