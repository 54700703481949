import { useSelector } from 'react-redux';
//selectors
import { getLangJson } from '../../store/selectors/LangSelector';
import { getSelectedDesign } from '../../store/selectors/DesignSelector';
// import { getBankDetailsData } from '../../store/selectors/BankSelector';
//svg
import {ReactComponent as Close} from '../../assets/images/close.svg';
import {ReactComponent as UnderReview} from '../../assets/images/under-review.svg';
import {ReactComponent as Accepted} from '../../assets/images/accepted.svg';
import {ReactComponent as Rejected} from '../../assets/images/rejected.svg';
//components
import DesignCard from '../design/DesignCard';
// import BankDetailsCard from '../home/BankDetailsCard';
// //actions
// import { getBankDetails } from '../../store/actions/BankActions';

function StatusModal({handleToggle}) {
  const t = useSelector((state) => getLangJson({ state })),
        data = useSelector((state) => getSelectedDesign({ state }));
        // bankDetails = useSelector((state) => getBankDetailsData({ state })),
        // dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getBankDetails());
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // },[]);

  return (
    <>
      <div className="status-modal-header">
        <Close className="close-icon" onClick={() => handleToggle(false)} />
        <p className="medium-txt sz-16 title-color">{t.requestStatus.title}</p>
      </div>
      <div className="status-modal-body">
        <div className="status-icon">
          {data?.status === 0? <UnderReview/> : data?.status === 1? <Accepted/> : data?.status === 2? <Rejected/> : null}
        </div>
        <p className="medium-txt sz-20 title-color">{data?.status === 0? t.requestStatus.underReviewMsg : data?.status === 1? t.requestStatus.acceptedMsg : data?.status === 2? t.requestStatus.rejectedMsg : null}</p>
        <p className="regular-txt sz-12 subtitle-color">{data?.status === 0? t.requestStatus.underReviewDesc : data?.status === 1? t.requestStatus.acceptedDesc : data?.status === 2? t.requestStatus.rejectedDesc : null}</p>
        <DesignCard data={data} isClickable={false} handleToggle={handleToggle} />
        {/* {data?.status === 1 && (
          <>
            <p className="medium-txt sz-16 title-color transferring-to-title">{t.home.transferTo}</p>
            <BankDetailsCard data={bankDetails}/>
          </>
        )} */}
      </div>
    </>
  )
}

export default StatusModal;