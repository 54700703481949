import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
//selectors
import { getLangJson } from '../../store/selectors/LangSelector';
import { getFetching } from '../../store/selectors/AppSelector';
//svg
import {ReactComponent as CreditCard} from '../../assets/images/creditCard.svg';
//components
import Loader from '../../components/common/Loader';

function BankDetailsCard({data}) {
  const t = useSelector((state) => getLangJson({ state })),
        isFetching = useSelector((state) => getFetching({ state })),
        history = useHistory();

  return (
    <div className="card card-shadow">
      {isFetching?
        <Loader heightval={118}/>
      :
        <>
          <div className="bankdetails-card-header">
            <div className="icon-container">
              <CreditCard/>
            </div>
            <div className="add-bankdetails-note">
              {
                Object.keys(data).length > 0?
                  <>
                    <p className="medium-txt sz-16 title-color account-name">{data.accountName}</p>
                    <p className="medium-txt sz-12 title-design bank-name">{t.home.bankName}{' '}<span className="regular-txt light-date">{data.bankName}</span></p>
                    <p className="medium-txt sz-12 title-design account-no">{t.home.accountNo}{' '}<span className="regular-txt light-date">{data.accountNumber}</span></p>
                  </>
                :
                  <p className="medium-txt sz-14 title-color">{t.home.addBankDetails}</p>
              }
            </div>
          </div>
          <div className={`bankdetails-card-footer ${Object.keys(data).length > 0? "change-bankDetails-container" : ""}`}>
            {
              Object.keys(data).length > 0?
                <p onClick={() => history.push('/add-bank-details')} className="regular-txt sz-12 primary-color change-bankDetails">{t.home.changeBankDetails}</p>
              :
                <button onClick={() => history.push('/add-bank-details')} className="add-bankdetails-btn regular-txt sz-12">{t.home.addBankDetailsBtn}</button>
            }
          </div>
        </>
      }
    </div>
  );
}

export default BankDetailsCard;