import { SET_HAS_JOINED, SET_LOADING, SET_REPORT_FETCHING, SET_FETCHING, SET_TOKEN, SET_IS_MOBILE } from '../types/AppTypes';
//constants
import { updateObject } from '../../constants/Helpers';

const initialState = {
	loading: false,
  fetching: false,
  report_fetching: false,
  hasJoined: false,
  token: '',
  isMobile: true
};

const AppReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING: {
      return updateObject(state, { loading: action.load });
    }
    case SET_FETCHING: {
      return updateObject(state, { fetching: action.fetching });
    }
    case SET_REPORT_FETCHING: {
      return updateObject(state, { report_fetching: action.report_fetching });
    }
    case SET_HAS_JOINED: {
      return updateObject(state, { hasJoined: action.hasJoined });
    }
    case SET_TOKEN: {
      return updateObject(state, { token: action.token });
    }
    case SET_IS_MOBILE: {
      return updateObject(state, { isMobile: action.isMobile });
    }
		default:
			return state;
	}
};

export default AppReducer;