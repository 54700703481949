import React from 'react';
import { useSelector } from 'react-redux';
//selector
import { getLoading } from '../../store/selectors/AppSelector';
//component
import Loader from './Loader';

function FixedButton({title, handleClick, disabled}) {
  const isLoading = useSelector((state) => getLoading({ state }));

  return (
    <div className='fixed-button'>
      <button disabled={isLoading || disabled} className={`btn florist-btn medium-txt ${isLoading || disabled? 'disabled':''}`} onClick={handleClick}>
        {isLoading? <Loader/> :title}
      </button>

    </div>

  );
}

export default FixedButton;
