export const getLandingPageUrl = () => "/";

export const getHomePageUrl = () => "/home";

export const getAddBankDetailsPageUrl = () => "/add-bank-details";

export const getAddDesignPageUrl = () => "/add-design";

export const getDesignsPageUrl = () => "/designs";

export const getTransactionHistoryPageUrl = () => "/transactions-history";

export const getHowItWorksPageUrl = () => "/how-it-works";
