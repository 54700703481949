import { 
  SET_SELECTED_DESIGN, 
  GET_ALL_DESIGNS, 
  GET_FLOWER_TYPES, 
  SET_SELECTED_FLOWER_TYPE,
  GET_UNDERREVIEW_DESIGNS,
  GET_ACCEPTED_DESIGNS,
  GET_REJECTED_DESIGNS,
  SET_FLOWER_TYPES_PAGE_COUNT,
  SET_UNDERREVIEW_DESIGNS_PAGE_COUNT,
  SET_ACCEPTED_DESIGNS_PAGE_COUNT,
  SET_REJECTED_DESIGNS_PAGE_COUNT,
} from '../types/DesignTypes';
//actions
import { setFetching } from './AppActions';
//service
import DesignService from '../../services/DesignService';
//toast
import { toast } from 'react-toastify';
//selector
import { getFlowerTypesData, getUnderReviewDesignsData, getAcceptedDesignsData, getRejectedDesignsData } from '../selectors/DesignSelector';

export const setSelectedDesign = (design) => ({
	type: SET_SELECTED_DESIGN,
	design,
});

export const getAllDesigns = () => async (dispatch) => {
	dispatch(setFetching(true));
  const params = {
    status: -1
  }
	try {
    const res = await DesignService.getAllDesigns(params);
    dispatch({
        type: GET_ALL_DESIGNS,
        allDesigns: res.data.data,
    });
    dispatch(setFetching(false));
  } catch (err) {
      dispatch(setFetching(false));
      toast.error(err?.response?.data?.message);
  }
};

export const getUnderReviewDesigns = (page) => async (dispatch, getState) => {
	dispatch(setFetching(true));
  const params = {
          status: 0,
          page: page
        },
        state = getState();
  let oldDesigns = getUnderReviewDesignsData({ state });
	try {
    const res = await DesignService.getAllDesigns(params);
    const oldCurrentPage = Math.ceil(oldDesigns.length/res.data.pageSize);
    if(page > oldCurrentPage){
      dispatch({
        type: GET_UNDERREVIEW_DESIGNS,
        underReviewDesigns: oldDesigns.concat(res.data.data),
      });
    }else{
      if(oldCurrentPage > 1 && page === 1){
        dispatch({
          type: GET_UNDERREVIEW_DESIGNS,
          underReviewDesigns: res.data.data,
        });
      }else{
        oldDesigns = oldDesigns.splice(0,((page-1) * res.data.pageSize));
        dispatch({
          type: GET_UNDERREVIEW_DESIGNS,
          underReviewDesigns: oldDesigns.concat(res.data.data),
        });
      }
    }
    dispatch({
      type: SET_UNDERREVIEW_DESIGNS_PAGE_COUNT,
      underReviewDesignsPageCount: res.data.pageCount,
    });
    dispatch(setFetching(false));
  } catch (err) {
      dispatch(setFetching(false));
      toast.error(err?.response?.data?.message);
  }
};

export const getAcceptedDesigns = (page) => async (dispatch, getState) => {
	dispatch(setFetching(true));
  const params = {
          status: 1,
          page: page
        },
        state = getState();
  let oldDesigns = getAcceptedDesignsData({ state });
	try {
    const res = await DesignService.getAllDesigns(params);
    const oldCurrentPage = Math.ceil(oldDesigns.length/res.data.pageSize);
    if(page > oldCurrentPage){
      dispatch({
        type: GET_ACCEPTED_DESIGNS,
        acceptedDesigns: oldDesigns.concat(res.data.data),
      });
    }else{
      if(oldCurrentPage > 1 && page === 1){
        dispatch({
          type: GET_ACCEPTED_DESIGNS,
          acceptedDesigns: res.data.data,
        });
      }else{
        oldDesigns = oldDesigns.splice(0,((page-1) * res.data.pageSize));
        dispatch({
          type: GET_ACCEPTED_DESIGNS,
          acceptedDesigns: oldDesigns.concat(res.data.data),
        });
      }
    }
    dispatch({
      type: SET_ACCEPTED_DESIGNS_PAGE_COUNT,
      acceptedDesignsPageCount: res.data.pageCount,
    });
    dispatch(setFetching(false));
  } catch (err) {
      dispatch(setFetching(false));
      toast.error(err?.response?.data?.message);
  }
};

export const getRejectedDesigns = (page) => async (dispatch, getState) => {
	dispatch(setFetching(true));
  const params = {
          status: 2,
          page: page
        },
        state = getState();
  let oldDesigns = getRejectedDesignsData({ state });
	try {
    const res = await DesignService.getAllDesigns(params);
    const oldCurrentPage = Math.ceil(oldDesigns.length/res.data.pageSize);
    if(page > oldCurrentPage){
      dispatch({
        type: GET_REJECTED_DESIGNS,
        rejectedDesigns: oldDesigns.concat(res.data.data),
      });
    }else{
      if(oldCurrentPage > 1 && page === 1){
        dispatch({
          type: GET_REJECTED_DESIGNS,
          rejectedDesigns: res.data.data,
        });
      }else{
        oldDesigns = oldDesigns.splice(0,((page-1) * res.data.pageSize));
        dispatch({
          type: GET_REJECTED_DESIGNS,
          rejectedDesigns: oldDesigns.concat(res.data.data),
        });
      }
    }
    dispatch({
      type: SET_REJECTED_DESIGNS_PAGE_COUNT,
      rejectedDesignsPageCount: res.data.pageCount,
    });
    dispatch(setFetching(false));
  } catch (err) {
      dispatch(setFetching(false));
      toast.error(err?.response?.data?.message);
  }
};

export const getFlowerTypes = (params) => async (dispatch, getState) => {
	dispatch(setFetching(true));
	try {
    const state = getState(),
          oldTypes = getFlowerTypesData({ state });
    const res = await DesignService.getFlowerTypes(params);
    dispatch({
        type: GET_FLOWER_TYPES,
        flowerTypes: oldTypes.concat(res.data.data),
    });
    dispatch({
      type: SET_FLOWER_TYPES_PAGE_COUNT,
      flowerTypesPageCount: res.data.pageCount,
    });
    dispatch(setFetching(false));
  } catch (err) {
      dispatch(setFetching(false));
      toast.error(err?.response?.data?.message);
  }
};

export const setSelectedFlowerType = (selectedFlowerType) => ({
	type: SET_SELECTED_FLOWER_TYPE,
	selectedFlowerType,
});

export const deleteDesign = (designId) => async (dispatch, getState) => {
  dispatch(setFetching(true));
	try {
    const state = getState(),
          oldDesigns = getRejectedDesignsData({ state });
    const res = await DesignService.deleteDesign(designId);
    let newDesigns = oldDesigns.filter(function(d){return d.id !== designId});
    dispatch({
        type: GET_REJECTED_DESIGNS,
        rejectedDesigns: newDesigns,
    });
    dispatch(setFetching(false));
    toast.success(res?.data?.message);
  } catch (err) {
      dispatch(setFetching(false));
      toast.error(err?.response?.data?.message);
  }
};