import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
//svg
import {ReactComponent as Back} from '../assets/images/back.svg';
import prepare from '../assets/images/landing-icon1.svg';
import upload from '../assets/images/landing-icon2.svg';
import approval from '../assets/images/landing-icon3.svg';
import commission from '../assets/images/landing-icon4.svg';
//components
import FixedButton from 'components/common/FixedButton';
//selectors
import { getLangJson, getLang } from 'store/selectors/LangSelector';
import { getHasJoined, getIsMobile } from 'store/selectors/AppSelector';
//service
import HomeService from 'services/HomeService';
import CommunicationService from 'services/CommunicationService';
//actions
import { setLoading, setHasJoined } from 'store/actions/AppActions';

function Landing ({ location }) {
  const t = useSelector((state) => getLangJson({ state })),
        lang = useSelector((state) => getLang({ state })),
        hasJoined = useSelector((state) => getHasJoined({ state })),
        isMobile = useSelector((state) => getIsMobile({ state })),
        history = useHistory(),
        dispatch = useDispatch();
  const handleJoin = async () => {
    dispatch(setLoading(true));
    try {
      // @TODO - Validate user (guest/loggedin) and pass necessary params
      // and pass required params
      const tempData = { key: 'joinButtonClicked', isLoggedIn: false, isJoined: false }; // this is temp object and we need to replace it with proper data
      CommunicationService.joinButtonClicked(tempData);
      const res = await HomeService.joinFC();
      toast.success(res?.data?.message);
      history.push('/home');
      dispatch(setHasJoined(true));
      dispatch(setLoading(false));
    } catch (err) {
      dispatch(setLoading(false));
      toast.error(err?.response?.data?.message);
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <>
      {!isMobile && location.pathname !== '/' && (
        <Back className='landing-back-icon' onClick={handleBack} />
      )}
      <div className={`banner ${lang === 'ar' ? 'banner-ar' : 'banner-en'}`}></div>
      <div className='landing-desc'>
        <p className='title sz-14 regular-txt subtitle-color'>{t.landing.desc}</p>
      </div>
      <div className="landing-container">
        <div className="howItWorks">
          <p className="sz-16 title-color bold-txt landing-page-title">{t.landing.howItWorks}</p>
          <div className="howItWorks-item">
            <img className="howItWorks-item-img" src={prepare} alt={"prepare"} />
            <p className="title-color sz-16 medium-txt howItWorks-item-title">
              {t.landing.step1Title}
            </p>
            <p className='subtitle-color sz-12 regular-txt howItWorks-item-desc'>
              {t.landing.step1Desc}
            </p>
          </div>
          <div className="howItWorks-item">
            <img className="howItWorks-item-img" src={upload} alt={"upload"} />
            <p className="title-color sz-16 medium-txt howItWorks-item-title">
              {t.landing.step2Title}
            </p>
            <p className='subtitle-color sz-12 regular-txt howItWorks-item-desc'>
              {t.landing.step2Desc}
            </p>
          </div>
          <div className="howItWorks-item">
            <img className="howItWorks-item-img" src={approval} alt={"approval"} />
            <p className="title-color sz-16 medium-txt howItWorks-item-title">
              {t.landing.step3Title}
            </p>
            <p className='subtitle-color sz-12 regular-txt howItWorks-item-desc'>
              {t.landing.step3Desc}
            </p>
          </div>
          <div className="howItWorks-item">
            <img className="howItWorks-item-img" src={commission} alt={"commission"} />
            <p className="title-color sz-16 medium-txt howItWorks-item-title">
              {t.landing.step4Title}
            </p>
            <p className="subtitle-color sz-12 regular-txt howItWorks-item-desc">
              {t.landing.step4Desc}
            </p>
          </div>
        </div>
      </div>
      {!hasJoined && <FixedButton title={t.landing.joinBtn} handleClick={handleJoin} />}
    </>
  );
}

export default Landing;
