//services
import { apiService } from './HttpService';
//end points
import { bankDetailsEndPoint } from './EndPoints';

class BankService {
  static addBankDetails(data) {
		return apiService({
			method: 'POST',
			url: bankDetailsEndPoint(),
      data
		});
	}

  static getBankDetailsData() {
		return apiService({
			method: 'GET',
			url: bankDetailsEndPoint(),
		});
	}

  static updateBankDetails(data) {
		return apiService({
			method: 'PUT',
			url: bankDetailsEndPoint(),
      data
		});
	}
}

export default BankService;