export const SET_LOADING = '[APP] SET_LOADING';

export const SET_FETCHING = '[APP] SET_FETCHING';

export const SET_REPORT_FETCHING = '[APP] SET_REPORT_FETCHING';

export const SET_HAS_JOINED = '[APP] SET_HAS_JOINED';

export const SET_TOKEN = '[APP] SET_TOKEN';

export const SET_IS_MOBILE = '[APP] SET_IS_MOBILE';