import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
//selectors
import { getLangJson } from '../../store/selectors/LangSelector';

function AddBouquetCard({location}) {
  const t = useSelector((state) => getLangJson({ state })),
        history = useHistory();

  return (
    <div className="card card-shadow clickable-card" onClick={() => history.push('/add-design')}>
      <div className="add-bouquet-card-header">

      </div>
      <div className="add-bouquet-card-footer">
        <button className='btn florist-btn'>
          {t.home.addDesign}
        </button>
        {/* <p className="medium-txt sz-16 primary-color">
          {t.home.addDesign}
        </p> */}

      </div>
    </div>
  );
}

export default AddBouquetCard;