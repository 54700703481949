import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { useHistory } from 'react-router';
//selectors
import { getLangJson } from '../store/selectors/LangSelector';
//components
import FixedButton from '../components/common/FixedButton';
import BalanceCard from '../components/home/BalanceCard';
import BankDetailsCard from '../components/home/BankDetailsCard';
// import TransactionCard from '../components/TransactionHistory/TransactionCard';
//actions
import { getBankDetails } from '../store/actions/BankActions';
import { getBalance } from '../store/actions/HomeActions';
// import { getAllTransactionsHistory } from '../store/actions/TransactionActions';
//selectors
import { getBalanceData } from '../store/selectors/HomeSelector';
import { getBankDetailsData } from '../store/selectors/BankSelector';
// import { getTransactionsHistoryData } from '../store/selectors/TransactionSelector';
//service
import TransactionService from '../services/TransactionService';
//toast
import {toast} from 'react-toastify';

function TransactionsHistory() {
  const t = useSelector((state) => getLangJson({ state })),
        balance = useSelector((state) => getBalanceData({ state })),
        bankDetails = useSelector((state) => getBankDetailsData({ state })),
        // transactions = useSelector((state) => getTransactionsHistoryData({ state })),
        [disableBtn, setDisableBtn] = useState(true),
        dispatch = useDispatch();
        // history = useHistory();

  useEffect(() => {
    if(Object.keys(bankDetails).length === 0)
      dispatch(getBankDetails());
    if(Object.keys(balance).length === 0)
      dispatch(getBalance());
    // if(Object.keys(transactions).length === 0)
    //   dispatch(getAllTransactionsHistory({page: 1}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(Object.keys(balance).length > 0 && balance.balance > 0)
      setDisableBtn(false)
  },[balance]);

  const withDraw = async () => {
    setDisableBtn(true);
    try {
      const res = await TransactionService.transfer();
      toast.success(res?.data?.message?? "Transferred has been successfully");
      dispatch(getBalance());
      setDisableBtn(false);
    } catch (err) {
      setDisableBtn(false);
      toast.error(err?.response?.data?.message?? "Transferred has been failed");
    }
  };

  return (
    <div className="transaction-history-body">
      <div className="form-container">
        <BalanceCard data={balance} withnote={true} />
        <p className="medium-txt sz-16 title-color transferring-to-title">{t.home.transferTo}</p>
        <BankDetailsCard data={bankDetails} />
        {/* <p className="medium-txt sz-16 title-color transferring-to-title">{t.transactionHistory.transactionHistory}</p> */}
        {/* {transactions.map(function(tr){
          return (<TransactionCard data={tr} />)
        })} */}
      </div>
      <FixedButton disabled={disableBtn} handleClick={withDraw} title={t.home.transfer} />
    </div>
  );
}

export default TransactionsHistory;