import { SET_TRANSACTION_HISTORY_DATA, SET_TRANSACTION_HISTORY_PAGE_COUNT } from '../types/TransactionTypes';
//constants
import { updateObject } from '../../constants/Helpers';

const initialState = {
	transactionHistory: [],
  transactionHistoryPageCount: 1
};

const TransactionReducer = (state = initialState, action) => {
	switch (action.type) {
    case SET_TRANSACTION_HISTORY_DATA: {
      return updateObject(state, { transactionHistory: action.transactionHistory });
    }
    case SET_TRANSACTION_HISTORY_PAGE_COUNT: {
      return updateObject(state, { transactionHistoryPageCount: action.transactionHistoryPageCount });
    }
    default:
      return state; 
	}
};

export default TransactionReducer;