//services
import { apiService, catalogService } from './HttpService';
//end points
import { designsEndPoint, flowerTypesEndPoint, designsByIdEndPoint } from './EndPoints';

class DesignService {
	static getAllDesigns(params) {
		return apiService({
			method: 'GET',
			url: designsEndPoint(),
      params
		});
	}

  static getFlowerTypes(params) {
		return catalogService({
			method: 'GET',
			url: flowerTypesEndPoint(),
      params
		});
	}

  static addDesign(data) {
		return apiService({
			method: 'POST',
			url: designsEndPoint(),
      data
		});
	}

  static deleteDesign(designId) {
		return apiService({
			method: 'DELETE',
			url: designsByIdEndPoint(designId),
		});
	}
}

export default DesignService;