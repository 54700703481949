import { combineReducers } from 'redux';
//reducers
import LangReducer from './reducers/LangReducer';
import HomeReducer from './reducers/HomeReducer';
import AppReducer from './reducers/AppReducer';
import DesignReducer from './reducers/DesignReducer';
import BankReducer from './reducers/BankReducer';
import TransactionReducer from './reducers/TransactionReducer';

const rootReducer = combineReducers({
  LangReducer,
  HomeReducer,
  AppReducer,
  DesignReducer,
  BankReducer,
  TransactionReducer,
});

export default rootReducer;