//URLs
import { 
  getLandingPageUrl, 
  getHomePageUrl, 
  getAddBankDetailsPageUrl, 
  getAddDesignPageUrl, 
  getDesignsPageUrl,
  getTransactionHistoryPageUrl,
  getHowItWorksPageUrl,
} from "./AppUrls";
//Components
import Home from '../pages/Home';
import Landing from '../pages/Landing';
import AddBankDetails from '../pages/AddBankDetails';
import AddDesign from "../pages/AddDesign";
import Designs from "../pages/Designs";
import TransactionsHistory from "../pages/TransactionsHistory";

export const routes = [
	{
		path: () => getHomePageUrl(),
		Component: Home,
	},
	{
		path: () => getAddBankDetailsPageUrl(),
		Component: AddBankDetails,
	},
	{
		path: () => getAddDesignPageUrl(),
		Component: AddDesign,
	},
	{
		path: () => getDesignsPageUrl(),
		Component: Designs,
	},
  {
		path: () => getTransactionHistoryPageUrl(),
		Component: TransactionsHistory,
	},
	{
		path: () => getHowItWorksPageUrl(),
		Component: Landing,
	},
	{
		path: () => getLandingPageUrl(),
		Component: Landing,
	},
];