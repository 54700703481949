import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
//selectors
import { getLangJson } from '../store/selectors/LangSelector';
import { getUnderReviewDesignsData, getAcceptedDesignsData, getRejectedDesignsData } from '../store/selectors/DesignSelector';
import { getFetching } from '../store/selectors/AppSelector';
//components
import Tabs from '../components/common/Tabs';
import DesignCard from '../components/design/DesignCard';
import Popup from '../components/common/Popup';
import StatusModal from '../components/modals/StatusModal';
import Loader from '../components/common/Loader';
//actions
import { getUnderReviewDesigns, getAcceptedDesigns, getRejectedDesigns } from '../store/actions/DesignActions';

function Designs({location}) {
  const t = useSelector((state) => getLangJson({ state })),
        [isOpen, handleToggle] = useState(false),
        underReviewDesigns = useSelector((state) => getUnderReviewDesignsData({ state })),
        acceptedDesigns = useSelector((state) => getAcceptedDesignsData({ state })),
        rejectedDesigns = useSelector((state) => getRejectedDesignsData({ state })),
        [underReviewPage, setUnderReviewPage] = useState(1),
        [acceptedPage, setAcceptedPage] = useState(1),
        [rejectedPage, setRejectedPage] = useState(1),
        isFetching = useSelector((state) => getFetching({ state })),
        dispatch = useDispatch();

  useEffect(() => {
    // if(underReviewDesigns.length === 0)
      dispatch(getUnderReviewDesigns(1));
    // if(acceptedDesigns.length === 0)
      dispatch(getAcceptedDesigns(1));
    // if(rejectedDesigns.length === 0)
      dispatch(getRejectedDesigns(1));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return (
    <div>
      <Popup
        isOpen={isOpen}
        handleToggle={handleToggle}
      >
        <StatusModal handleToggle={handleToggle} />
      </Popup>
      <Tabs 
        activeTab={0} 
        underReviewPage={underReviewPage}
        setUnderReviewPage={setUnderReviewPage}
        acceptedPage={acceptedPage}
        setAcceptedPage={setAcceptedPage}
        rejectedPage={rejectedPage}
        setRejectedPage={setRejectedPage}
      >
          <div label={t.designs.urTab} classProp={"under-review"}>
            <div>
              {isFetching?
                <Loader heightval={300}/>
              :
                underReviewDesigns.length === 0?
                  <p className="bold-txt sz-16 title-color empty-txt white-space" dangerouslySetInnerHTML={{__html: t.designs.emptyUR}}/>
                :
                  underReviewDesigns.map((ur, i) => 
                    <DesignCard key={i} data={ur} isClickable={true} handleToggle={handleToggle} />
                  )
              }
              
            </div>
          </div>
          <div label={''} classProp={"split"}></div>
          <div label={t.designs.acTab} classProp={"accepted"}>
            <div>
              {
                isFetching?
                  <Loader heightval={300}/>
                :
                  acceptedDesigns.length === 0?
                    <p className="bold-txt sz-16 title-color empty-txt white-space" dangerouslySetInnerHTML={{__html: t.designs.emptyAc}}/>
                  :
                    acceptedDesigns.map((a, i) => 
                      <DesignCard key={i} data={a} isClickable={true} handleToggle={handleToggle} />
                    )
              }
            </div>
          </div>
          <div label={''} classProp={"split"}></div>
          <div label={t.designs.rjTab} classProp={"rejected"}>
            <div>
              {
                isFetching?
                  <Loader heightval={300}/>
                :
                  rejectedDesigns.length === 0?
                    <p className="bold-txt sz-16 title-color empty-txt white-space" dangerouslySetInnerHTML={{__html: t.designs.emptyRj}}/>
                  :
                    rejectedDesigns.map((r, i) => 
                      <DesignCard key={i} data={r} isClickable={true} handleToggle={handleToggle} />
                    )
              }
            </div>
          </div>
      </Tabs>
    </div>
  );
}

export default Designs;