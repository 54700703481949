export const SET_SELECTED_DESIGN = '[DESIGN] SET_SELECTED_DESIGN';

export const GET_ALL_DESIGNS = '[DESIGN] GET_ALL_DESIGNS';

export const GET_FLOWER_TYPES = '[DESIGN] GET_FLOWER_TYPES';

export const SET_SELECTED_FLOWER_TYPE = '[DESIGN] SET_SELECTED_FLOWER_TYPE';

export const GET_UNDERREVIEW_DESIGNS = '[DESIGN] GET_UNDERREVIEW_DESIGNS';

export const GET_ACCEPTED_DESIGNS = '[DESIGN] GET_ACCEPTED_DESIGNS';

export const GET_REJECTED_DESIGNS = '[DESIGN] GET_REJECTED_DESIGNS';

export const SET_FLOWER_TYPES_PAGE_COUNT = '[DESOGN] SET_FLOWER_TYPES_PAGE_COUNT';

export const SET_UNDERREVIEW_DESIGNS_PAGE_COUNT = '[DESOGN] SET_UNDERREVIEW_DESIGNS_PAGE_COUNT';

export const SET_ACCEPTED_DESIGNS_PAGE_COUNT = '[DESOGN] SET_ACCEPTED_DESIGNS_PAGE_COUNT';

export const SET_REJECTED_DESIGNS_PAGE_COUNT = '[DESOGN] SET_REJECTED_DESIGNS_PAGE_COUNT';