export const getSelectedDesign = ({ state }) => state.DesignReducer.design;

export const getAllDesignsData = ({ state }) => state.DesignReducer.allDesigns;

export const getFlowerTypesData = ({ state }) => state.DesignReducer.flowerTypes;

export const getFlowerTypesPageCountData = ({ state }) => state.DesignReducer.flowerTypesPageCount;

export const getSelectedFlowerType = ({ state }) => state.DesignReducer.selectedFlowerType;

export const getUnderReviewDesignsData = ({ state }) => state.DesignReducer.underReviewDesigns;

export const getUnderReviewDesignsPageCountData = ({ state }) => state.DesignReducer.underReviewDesignsPageCount;

export const getAcceptedDesignsData = ({ state }) => state.DesignReducer.acceptedDesigns;

export const getAcceptedDesignsPageCountData = ({ state }) => state.DesignReducer.acceptedDesignsPageCount;

export const getRejectedDesignsData = ({ state }) => state.DesignReducer.rejectedDesigns;

export const getRejectedDesignsPageCountData = ({ state }) => state.DesignReducer.rejectedDesignsPageCount;