import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
//selectors
import { getLangJson } from '../store/selectors/LangSelector';
//svg
import { ReactComponent as CCIcon } from '../assets/images/ccIcon.svg';
//components
import Loader from '../components/common/Loader';
import FixedButton from '../components/common/FixedButton';
//actions
import { getBankDetails } from '../store/actions/BankActions';
import { setBankDetails } from '../store/actions/BankActions';
import { setLoading } from '../store/actions/AppActions';
//selectors
import { getBankDetailsData } from '../store/selectors/BankSelector';
import { getFetching } from '../store/selectors/AppSelector';
//service
import BankService from '../services/BankService';
//toast
import { toast } from 'react-toastify';
//types
import { UPDATE_BANK_DETAILS } from '../store/types/BankTypes';

function AddBankDetails({ location }) {
  const t = useSelector((state) => getLangJson({ state })),
        [name, setName] = useState(''),
        [bank, setBank] = useState(''),
        [accNo, setAccNo] = useState(''),
        [iban, setIban] = useState(''),
        [disabled, setDisabled] = useState(true),
        bankDetails = useSelector((state) => getBankDetailsData({ state })),
        isFetching = useSelector((state) => getFetching({ state })),
        dispatch = useDispatch(),
        history = useHistory();

  const handleSubmit = async () => {
    const data = {
      accountName: name,
      bankName: bank,
      accountNumber: accNo,
      iban: iban
    }
    if(Object.keys(bankDetails).length>0){
      dispatch(setLoading(true));
      try {
        const res = await BankService.updateBankDetails(data);
        dispatch({
          type: UPDATE_BANK_DETAILS,
          bankDetails: data,
        });
        dispatch(setLoading(false));
        toast.success(res?.data?.message?? "Bank details are updated successfully!");
        history.goBack();
      } catch (err) {
        dispatch(setLoading(false));
        toast.error(err?.response?.data?.message);
      }
    }else{
      dispatch(setBankDetails(data));
    }
  };

  const checkValidation = (nameVal, bankVal, accNoVal, ibanVal) => {
    if(nameVal !== "" && bankVal !== "" && accNoVal !== "" && ibanVal !== ""){
      setDisabled(false);
    }else{
      setDisabled(true);
    }
  };

  useEffect(() => {
    dispatch(getBankDetails());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if(Object.keys(bankDetails).length>0){
      setName(bankDetails?.accountName);
      setBank(bankDetails?.bankName);
      setAccNo(bankDetails?.accountNumber);
      setIban(bankDetails?.iban);
      checkValidation(bankDetails?.accountName, bankDetails?.bankName, bankDetails?.accountNumber, bankDetails?.iban);
    }
  },[bankDetails]);

  return (
    <>
      <div className="form-container">
        <div className="yellow-note bank-note">
          <div>
            <CCIcon/>
          </div>
          <p className="medium-txt sz-14 title-txt">{t.addBankDetails.bankNote}</p>
        </div>
        <p className="medium-txt sz-16 title-color">{t.addBankDetails.yourBankDetails}</p>{
          isFetching?
            <Loader heightval={300}/>
          :
          <form className="bank-form">
            <div className="input-container">
              {name !== '' && (<label htmlFor="name" className="custom-label medium-txt sz-10 primary-color">{t.addBankDetails.nameLabel}</label>)}
              <input id="name" className={`custom-input ${name !== ''? 'input-with-label' : ''}`} placeholder={t.addBankDetails.nameLabel} defaultValue={name} onChange={(e) => {setName(e.target.value);checkValidation(e.target.value, bank, accNo, iban);}} />
            </div>
            <div className="input-container">
              {bank !== '' && (<label htmlFor="bank" className="custom-label medium-txt sz-10 primary-color">{t.addBankDetails.bankLabel}</label>)}
              <input id="bank" className={`custom-input ${bank !== ''? 'input-with-label' : ''}`} placeholder={t.addBankDetails.bankLabel} defaultValue={bank} onChange={(e) => {setBank(e.target.value);checkValidation(name, e.target.value, accNo, iban);}} />
            </div>
            <div className="input-container">
              {accNo !== '' && (<label htmlFor="accno" className="custom-label medium-txt sz-10 primary-color">{t.addBankDetails.accountNoLabel}</label>)}
              <input id="accno" type="number" className={`custom-input ${accNo !== ''? 'input-with-label' : ''}`} placeholder={t.addBankDetails.accountNoLabel} defaultValue={accNo} onChange={(e) => {setAccNo(e.target.value);checkValidation(name, bank, e.target.value, iban);}} />
            </div>
            <div className="input-container">
              {iban !== '' && (<label htmlFor="iban" className="custom-label medium-txt sz-10 primary-color">{t.addBankDetails.ibanLabel}</label>)}
              <input id="iban" type="text" className={`custom-input ${iban !== ''? 'input-with-label' : ''}`} placeholder={t.addBankDetails.ibanLabel} defaultValue={iban} onChange={(e) => {setIban(e.target.value);checkValidation(name, bank, accNo, e.target.value);}} />
            </div>
          </form>
        }
      </div>
      <FixedButton disabled={disabled} handleClick={handleSubmit} title={t.addBankDetails.save} />
    </>
  );
}

export default AddBankDetails;