import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
//actions
import { getUnderReviewDesigns, getAcceptedDesigns, getRejectedDesigns } from '../../store/actions/DesignActions';
//selectors
import { getUnderReviewDesignsPageCountData, getAcceptedDesignsPageCountData, getRejectedDesignsPageCountData } from '../../store/selectors/DesignSelector';

const Tabs = (
  { 
    activeTab, 
    underReviewPage, 
    setUnderReviewPage, 
    acceptedPage, 
    setAcceptedPage, 
    rejectedPage, 
    setRejectedPage, 
    children 
  }) => {
	const [currentActiveTab, setCurrentActiveTab] = useState(0),
        underReviewDesignsPageCount = useSelector((state) => getUnderReviewDesignsPageCountData({ state })),
        acceptedDesignsPage = useSelector((state) => getAcceptedDesignsPageCountData({ state })),
        rejectedDesignsPage = useSelector((state) => getRejectedDesignsPageCountData({ state })),
        dispatch = useDispatch();

	useEffect(() => {
    document.getElementsByClassName("tab-content-under-review")[0].onscroll = debounce(() => {
        if (document.getElementsByClassName("tab-content-under-review")[0].offsetHeight !== 0 &&(Math.round(document.getElementsByClassName("tab-content-under-review")[0].offsetHeight + document.getElementsByClassName("tab-content-under-review")[0].scrollTop) >= document.getElementsByClassName("tab-content-under-review")[0].scrollHeight)) {
          if(underReviewPage < underReviewDesignsPageCount){
            dispatch(getUnderReviewDesigns(underReviewPage+1));
            setUnderReviewPage(underReviewPage+1);
          }
        }
    }, 50);
    document.getElementsByClassName("tab-content-accepted")[0].onscroll = debounce(() => {
      if (document.getElementsByClassName("tab-content-accepted")[0].offsetHeight !== 0 &&(Math.round(document.getElementsByClassName("tab-content-accepted")[0].offsetHeight + document.getElementsByClassName("tab-content-accepted")[0].scrollTop) >= document.getElementsByClassName("tab-content-accepted")[0].scrollHeight)) {
        if(acceptedPage < acceptedDesignsPage){
          dispatch(getAcceptedDesigns(acceptedPage+1));
          setAcceptedPage(acceptedPage+1);
        }
      }
    }, 50);
    document.getElementsByClassName("tab-content-rejected")[0].onscroll = debounce(() => {
      if (document.getElementsByClassName("tab-content-rejected")[0].offsetHeight !== 0 &&(Math.round(document.getElementsByClassName("tab-content-rejected")[0].offsetHeight + document.getElementsByClassName("tab-content-rejected")[0].scrollTop) >= document.getElementsByClassName("tab-content-rejected")[0].scrollHeight)) {
        if(rejectedPage < rejectedDesignsPage){
          dispatch(getRejectedDesigns(rejectedPage+1));
          setRejectedPage(rejectedPage+1);
        }
      }
    }, 50);
		if (activeTab !== 0 && activeTab < children.length) {
			setCurrentActiveTab(activeTab);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onChangeTab = (index) => {
		setCurrentActiveTab(index);
	};

	return (
		<div className="tabs">
			<div className="labels-wrapper">
				{children.map((el, i) => (
					el.props.classProp === 'split'?
            <div key={i} className={`tab-split ${((currentActiveTab === 0 && i === 3) || (currentActiveTab === 4 && i === 1))? '' : 'hide'} `}></div>
          :
            <div
              className={`medium-txt sz-14 title-color tab-label ${currentActiveTab === i ? ' active-tab-label' : ''}`}
              key={i}
              onClick={() => onChangeTab(i)}
            >
              {el.props.label}
            </div>
				))}
			</div>
			<div className="tabs-content-wrapper">
				{children.map((el, i) => (
					<div
						className={`tab-content tab-content-${el.props.classProp} ${currentActiveTab === i ? 'active-tab-content' : ''}`}
						key={i}
					>
						{el.props.children}
					</div>
				))}
			</div>
		</div>
	);
};

Tabs.propTypes = {
	activeTab: PropTypes.number.isRequired,
};

export default Tabs;
