export const getLoading = ({ state }) => state.AppReducer.loading;

export const getReportFetching = ({ state }) => state.AppReducer.report_fetching;

export const getFetching = ({ state }) => state.AppReducer.fetching;

export const getHasJoined = ({ state }) => state.AppReducer.hasJoined;

export const getToken = ({ state }) => state.AppReducer.token;

export const getIsMobile = ({ state }) => state.AppReducer.isMobile;